import { Tooltip, Typography } from '@mui/material';
import { add, format, type Locale as DateFnsLocale } from 'date-fns';
import React from 'react';

import { DATE_FORMAT_LONG_LOCALIZED } from '../../../constants';
import type { Filter } from '../../../models/filters';
import { AccountFilterType } from '../../../models/filters';
import type { UUID } from '../../../models/uuid';
import { filterCustomers$ } from '../../../selectors';
import { formatUSDCurrencyShort } from '../../../utils';

// FIXME: need formatBusinessCurrencyShort here 🚨

/**
 * Get's the element for a filter label.
 * @param filter to build the node for
 * @param locale to format dates in
 * @returns element label
 */
export function getFilterLabel(filter: Filter, locale?: DateFnsLocale): React.ReactNode {
  switch (filter.type) {
    case AccountFilterType.AccountNameContains:
      return filter.value.map((value, i) => (
        <React.Fragment key={i}>
          customer <strong>{value}</strong>
        </React.Fragment>
      ));
    case AccountFilterType.IndustryContains:
      return filter.value.map((value, i) => (
        <React.Fragment key={i}>
          industry <strong>{value}</strong>
        </React.Fragment>
      ));
    case AccountFilterType.NotHasProduct:
      return (
        <>
          <strong>{filter.productName}</strong> whitespace
        </>
      );
    case AccountFilterType.ProductOverageAtLeast:
      return (
        <>
          <strong>{filter.productName}</strong> overage &ge;{' '}
          <strong>{formatUSDCurrencyShort(filter.value)}</strong>
        </>
      );
    case AccountFilterType.PrimaryOwner:
    case AccountFilterType.SecondaryOwner:
    case AccountFilterType.RenewalOwner:
      return filter.owners.map((owner, i) => (
        <React.Fragment key={i}>
          led by <strong>{owner}</strong>
        </React.Fragment>
      ));
    case AccountFilterType.DaysUntilRenewal:
      return (
        <Tooltip
          title={
            <Typography>
              {format(add(Date.now(), { days: filter.days }), DATE_FORMAT_LONG_LOCALIZED, {
                locale,
              })}
            </Typography>
          }
        >
          <span data-uid={filterCustomers$.details.renewsWithinDaysTooltip}>
            renews within <strong>{filter.days} days</strong>
          </span>
        </Tooltip>
      );
    case AccountFilterType.RenewalFiscalQuarter:
      return (
        <>
          renews in FY
          <strong>{filter.year}</strong>
          {filter.quarter != null ? (
            <>
              {' '}
              Q<strong>{filter.quarter}</strong>
            </>
          ) : null}
        </>
      );
    case AccountFilterType.ArrAtLeast:
      return (
        <>
          ARR &ge; <strong>{formatUSDCurrencyShort(filter.value)}</strong>
        </>
      );
    case AccountFilterType.ArrAtMost:
      return (
        <>
          ARR &le; <strong>{formatUSDCurrencyShort(filter.value)}</strong>
        </>
      );
    case AccountFilterType.PipelineAtLeast:
      return (
        <>
          pipeline &ge; <strong>{formatUSDCurrencyShort(filter.value)}</strong>
        </>
      );
    case AccountFilterType.PipelineAtMost:
      return (
        <>
          pipeline &le; <strong>{formatUSDCurrencyShort(filter.value)}</strong>
        </>
      );
    case AccountFilterType.RegionContains:
      return filter.value.map((value, i) => (
        <React.Fragment key={i}>
          region <strong>{value}</strong>
        </React.Fragment>
      ));
    // TODO: support renewal date filters
    default:
      return <></>;
  }
}

interface FilterChip {
  id: UUID;
  label: string | React.ReactNode;
  type: AccountFilterType;
}

/**
 * Transform a filter list into the valid list of chips.
 * @param filters list of filters to transform to chip options
 * @param locale locale to format dates in
 * @returns list of filter chip options
 */
export function getFilterLabels(filters: Filter[], locale?: DateFnsLocale): FilterChip[] {
  return filters.reduce((chips: FilterChip[], f) => {
    const filterLabel = getFilterLabel(f, locale);
    if (Array.isArray(filterLabel)) {
      return [...chips, ...filterLabel.map((label) => ({ type: f.type, id: f.id, label }))];
    }
    return [...chips, { type: f.type, id: f.id, label: filterLabel }];
  }, []);
}
