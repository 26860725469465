import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { AccountFieldsFragment } from '../graphql/generated';
import {
  AccountStatus,
  GetFilterAccountsContextDocument,
  GetFilterDrawerContextDocument,
  GetMyFiltersDocument,
} from '../graphql/generated';
import { useDemoAugmenters } from '../graphql/hooks';
import type { Account } from '../models/account';
import type {
  AccountNameFilter,
  ArrAtLeastFilter,
  ArrAtMostFilter,
  DaysUntilRenewalFilter,
  Filter,
  IndustryFilter,
  NotHasProductFilter,
  PipelineAtLeastFilter,
  PipelineAtMostFilter,
  PrimaryOwnerFilter,
  ProductOverageAtLeastFilter,
  RegionFilter,
  RenewalFiscalQuarterFilter,
  RenewalOwnerFilter,
  SecondaryOwnerFilter,
} from '../models/filters';
import { AccountFilterType, toFilter } from '../models/filters';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';
import { getAccountFromAPIAccount } from './account';

interface AccountFilters {
  name: string;
  /**
   * Accounts this filter resolves to.
   */
  accounts: Account[];
  /**
   * A union of all of the filter operations for this account filter.
   */
  filters: Filter[];
}

/**
 * Hook to get an account filter and resolved accounts.
 * @param id of the filter to get - passing in an undefined filter id will result in a `no-value` state
 * @returns result state of the filter query
 */
export const useAccountFilter = (id: UUID | undefined) => {
  const { currencyCode } = useUserSettingsContext();
  const result = useResultFromQuery(
    useQuery(GetFilterAccountsContextDocument, id != null ? { variables: { id } } : { skip: true }),
  );

  const { augmentAPIAccountsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<AccountFilters, ApolloError> => {
    if (result.state !== ResultType.Value) {
      return result;
    }

    if (result.value.node.__typename !== 'Filter') {
      throw new Error('got an unexpected node type for filter query');
    }

    const filterAccounts = result.value.node.nodes.filter(
      (node): node is { id: UUID } & AccountFieldsFragment =>
        // TODO: remove churned check
        node.__typename === 'Account' && node.status !== AccountStatus.Churned,
    );
    const accounts = (
      shouldAugmentData ? augmentAPIAccountsWithDemoData(filterAccounts) : filterAccounts
    ).map((node) => getAccountFromAPIAccount(node, currencyCode));

    return {
      state: ResultType.Value,
      value: {
        name: result.value.node.name,
        filters: result.value.node.rootFilterJunction?.filters.map(toFilter) ?? [],
        accounts,
      },
    };
  }, [augmentAPIAccountsWithDemoData, currencyCode, shouldAugmentData, result]);
};

export const useMyFilters = () => {
  const result = useResultFromQuery(useQuery(GetMyFiltersDocument));
  return useMemo(() => {
    if (result.state != ResultType.Value) {
      return result;
    }

    return {
      state: ResultType.Value,
      value: result.value.self.associated.filters,
    };
  }, [result]);
};

interface FilterOperations {
  id: UUID;
  rootJunctionId: UUID | undefined;
  filterName: string;
  /**
   * Filters accounts by customer name.
   */
  nameFilter: AccountNameFilter | undefined;
  /**
   * Filters accounts by industry.
   */
  industryFilter: IndustryFilter | undefined;
  /**
   * Filters accounts if a specified product's attribute is null.
   */
  notHasProductFilters: NotHasProductFilter[];
  /**
   * Filters accounts if a specified product's attribute is at least some value.
   */
  productOverageAtLeastFilters: ProductOverageAtLeastFilter[];
  /**
   * Filters accounts by a specified primary owner.
   */
  primaryOwnerFilter: PrimaryOwnerFilter | undefined;
  /**
   * Filters accounts by a specified secondary owner.
   */
  secondaryOwnerFilter: SecondaryOwnerFilter | undefined;
  /**
   * Filters accounts by a specified renewal owner.
   */
  renewalOwnerFilter: RenewalOwnerFilter | undefined;
  /**
   * Filters accounts by renewal dates before a specified day.
   */
  upcomingRenewalFilters: [DaysUntilRenewalFilter] | [];
  /**
   * Filters accounts by renewal fiscal quarter.
   */
  renewalQuarterFilters: [RenewalFiscalQuarterFilter] | [];
  /**
   * Filters accounts by pipeline value.
   */
  pipelineFilters: Array<PipelineAtLeastFilter | PipelineAtMostFilter>;
  /**
   * Filters accounts by ARR value.
   */
  arrFilters: Array<ArrAtLeastFilter | ArrAtMostFilter>;
  /**
   * Filters accounts by region.
   */
  regionFilter: RegionFilter | undefined;
}

interface UseEditFilterProps {
  filterId: UUID | undefined;
}
export const useEditFilter = ({ filterId }: UseEditFilterProps) => {
  const result = useResultFromQuery(
    useQuery(
      GetFilterDrawerContextDocument,
      filterId != null ? { variables: { id: filterId } } : { skip: true },
    ),
  );

  return useMemo((): Result<FilterOperations, Error> => {
    if (result.state !== ResultType.Value) {
      return result;
    }
    if (result.value.node.__typename !== 'Filter') {
      return {
        state: ResultType.Error,
        value: new Error(`got invalid query result type ${result.value.node.__typename}`),
      };
    }

    const filters = result.value.node.rootFilterJunction?.filters.map(toFilter) ?? [];

    // because we are shifting the api - we need to handle one or many of these filters
    const nameFilter = filters.reduce(
      (accountNameFilter: AccountNameFilter | undefined, f): AccountNameFilter | undefined => {
        if (f.type === AccountFilterType.AccountNameContains) {
          if (accountNameFilter != null) {
            return { ...accountNameFilter, value: [...accountNameFilter.value, ...f.value] };
          }
          return f;
        }
        return accountNameFilter;
      },
      undefined,
    );
    const industryFilter = filters.reduce(
      (industryFilter: IndustryFilter | undefined, f): IndustryFilter | undefined => {
        if (f.type === AccountFilterType.IndustryContains) {
          if (industryFilter != null) {
            return { ...industryFilter, value: [...industryFilter.value, ...f.value] };
          }
          return f;
        }
        return industryFilter;
      },
      undefined,
    );
    const notHasProductFilters = filters.filter(
      (f): f is NotHasProductFilter => f.type === AccountFilterType.NotHasProduct,
    );
    const productOverageAtLeastFilters = filters.filter(
      (f): f is ProductOverageAtLeastFilter => f.type === AccountFilterType.ProductOverageAtLeast,
    );

    const primaryOwnerFilter = filters.find(
      (f): f is PrimaryOwnerFilter => f.type === AccountFilterType.PrimaryOwner,
    );
    const secondaryOwnerFilter = filters.find(
      (f): f is SecondaryOwnerFilter => f.type === AccountFilterType.SecondaryOwner,
    );
    const renewalOwnerFilter = filters.find(
      (f): f is RenewalOwnerFilter => f.type === AccountFilterType.RenewalOwner,
    );

    const upcomingRenewalFilter = filters.find(
      (f): f is DaysUntilRenewalFilter => f.type === AccountFilterType.DaysUntilRenewal,
    );
    const renewalQuarterFilter = filters.find(
      (f): f is RenewalFiscalQuarterFilter => f.type === AccountFilterType.RenewalFiscalQuarter,
    );
    const pipelineFilters = filters.filter(
      (f): f is PipelineAtLeastFilter | PipelineAtMostFilter =>
        f.type === AccountFilterType.PipelineAtLeast || f.type === AccountFilterType.PipelineAtMost,
    );
    const arrFilters = filters.filter(
      (f): f is ArrAtLeastFilter | ArrAtMostFilter =>
        f.type === AccountFilterType.ArrAtLeast || f.type === AccountFilterType.ArrAtMost,
    );

    const regionFilter = filters.reduce(
      (regionFilter: RegionFilter | undefined, f): RegionFilter | undefined => {
        if (f.type === AccountFilterType.RegionContains) {
          if (regionFilter != null) {
            return { ...regionFilter, value: [...regionFilter.value, ...f.value] };
          }
          return f;
        }
        return regionFilter;
      },
      undefined,
    );

    return {
      ...result,
      value: {
        id: result.value.node.id,
        rootJunctionId: result.value.node.rootFilterJunction?.id,
        filterName: result.value.node.name,
        nameFilter,
        industryFilter,
        notHasProductFilters,
        productOverageAtLeastFilters,
        primaryOwnerFilter,
        secondaryOwnerFilter,
        renewalOwnerFilter,
        upcomingRenewalFilters: upcomingRenewalFilter != null ? [upcomingRenewalFilter] : [],
        renewalQuarterFilters: renewalQuarterFilter != null ? [renewalQuarterFilter] : [],
        pipelineFilters,
        arrFilters,
        regionFilter,
      },
    };
  }, [result]);
};
