import type { ApolloError, OperationVariables, QueryResult } from '@apollo/client';
import { useMemo } from 'react';

export enum ResultType {
  /**
   * The result is still loading.
   */
  Loading = 'loading',
  /**
   * The result came back with no value. Represents a terminal state.
   */
  NoValue = 'no-value',
  /**
   * The result came back with a value. Represents a terminal state.
   */
  Value = 'value',
  /**
   * The result came back with an error. Represents a terminal state.
   */
  Error = 'error',
}

interface LoadingResult {
  state: ResultType.Loading;
  value: undefined;
}

interface ValueResult<T> {
  state: ResultType.Value;
  value: T;
}

interface NoValueResult {
  state: ResultType.NoValue;
  value: undefined;
}

interface ErrorResult<T> {
  state: ResultType.Error;
  value: T;
}

/**
 * Unioned type that can describe the result of an apollo query.
 * @param T the type of the result if it returns a value
 * @param U the type of the result if it returns and error
 */
export type Result<T, U> = LoadingResult | ValueResult<T> | NoValueResult | ErrorResult<U>;

/**
 * Hook to get a result type from an apollo query result.
 *
 * Apollo's QueryResult type has some really bad optional fields. It doesn't do a great job
 * of unioning the types between loading, error, and value result states.
 *
 * This function instead uses what it can see in the apollo result object and returns a unioned
 * state type. This way, we can just ask about the type of the response, rather than the state of the
 * data so we don't accidentally mix up a null response with a bad response, for example.
 * @param queryResult apollo query result
 * @returns result from the apollo query
 */
export function useResultFromQuery<V, U extends OperationVariables>(
  queryResult: QueryResult<V, U>,
): Result<V, ApolloError> {
  return useMemo(() => {
    if (queryResult.loading) {
      return { state: ResultType.Loading, value: undefined };
    }

    if (queryResult.error != undefined) {
      return { state: ResultType.Error, value: queryResult.error };
    }

    if (queryResult.data !== undefined) {
      return { state: ResultType.Value, value: queryResult.data };
    }

    return { state: ResultType.NoValue, value: undefined };
  }, [queryResult.loading, queryResult.error, queryResult.data]);
}
