import type React from 'react';
import { createContext, useContext } from 'react';

import type { UUID } from '../models/uuid';

export enum PersistenceKeys {
  HeatmapVariation = 'HeatmapVariation',
  HeatmapXAxisSelection = 'HeatmapXAxisSelection',
  HeatmapYAxisSelection = 'HeatmapYAxisSelection',
  HeatmapTileAccountsSelection = 'HeatmapTileAccountsSelection',
  CustomersGridRowsSelection = 'CustomersGridRowsSelection',
  CustomersGridColumnSorting = 'CustomersGridColumnSorting',
  CustomersGridColumnFiltering = 'CustomersGridColumnFiltering',
  CustomersNameSearch = 'CustomersNameSearch',
  StorageUpdatedAt = 'StorageUpdatedAt',
  // ListStorageUpdatedAt
}
interface PersistenceContextType {
  setFilterId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSprintId: React.Dispatch<React.SetStateAction<UUID | undefined>>;
  getStorageKey: (pk: PersistenceKeys) => string;
  setChanges: (pk: PersistenceKeys, count: number) => void;
  registerReset: (cb: () => void) => void;
  clearAllChanges?: () => void;
}
export const PersistenceContext = createContext<PersistenceContextType>({
  setFilterId: () => null,
  setSprintId: () => null,
  getStorageKey: () => '',
  setChanges: () => null,
  registerReset: () => null,
  clearAllChanges: () => null,
});

export const usePersistenceContext = () => useContext(PersistenceContext);
