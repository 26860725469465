import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';
import React from 'react';

export const HeaderTypography = ({ children, sx, ...rest }: PropsWithChildren<TypographyProps>) => (
  <Typography
    sx={{
      fontSize: '0.75rem',
      fontWeight: 400,
      color: (theme) => theme.palette.text.secondary,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
);
export const ValueTypography = ({ children, sx, ...rest }: PropsWithChildren<TypographyProps>) => (
  <Typography sx={{ fontSize: '1.125rem', fontWeight: 300, ...sx }} {...rest}>
    {children}
  </Typography>
);
