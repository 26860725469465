import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { AuthStatus, useReefAuthContext } from '../contexts/reefAuthContext';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type {
  ActionFieldsFragment,
  ClientStatus,
  GetClientConfigQuery,
} from '../graphql/generated';
import {
  AccountStatus,
  GetClientAccountsDocument,
  GetClientConfigDocument,
  GetClientObjectivesDocument,
  GetClientStatusDocument,
  GetCustomerSprintsPageDocument,
  GetObjectiveActionsDocument,
  GetSprintDashboardDocument,
} from '../graphql/generated';
import { useDemoAugmenters } from '../graphql/hooks';
import type { Account } from '../models/account';
import type { ClientConfig } from '../models/client';
import { EMPTY_CLIENT_CONFIG } from '../models/client';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { Action, Link, Objective, SprintDashboardElement } from '../models/sprint';
import type { UUID } from '../models/uuid';
import { transformDate } from '../utils';
import { getAccountFromAPIAccount } from './account';
import { transformSprintDashboard } from './sprint';

/**
 * Hook to get relevant outer client sprints data about a specific account.
 * @param params query params
 * @param params.accountId specific Account ID to scope fetched Sprints
 * @returns all fetched Sprints, transformed as Dashboard elements
 */
export const useGetCustomerSprintsPage = ({
  accountId,
}: {
  accountId?: UUID;
}): Result<SprintDashboardElement[], ApolloError> => {
  const queryResult = useResultFromQuery(
    useQuery(GetCustomerSprintsPageDocument, {
      variables: accountId != null ? { accountId } : undefined,
      skip: accountId == null,
    }),
  );

  return useMemo(() => {
    if (queryResult.state === ResultType.Value) {
      const allSprints = [
        ...queryResult.value.client.activeSprints.map(transformSprintDashboard),
        ...queryResult.value.client.completedSprints.map(transformSprintDashboard),
      ];

      return {
        ...queryResult,
        value: allSprints,
      };
    }

    return queryResult;
  }, [queryResult]);
};

/**
 * Hook to get relevant outer client sprints data about Reef customers.
 * @returns client sprints data for a given users authentication
 */
export const useGetClientSprintsList = (): Result<SprintDashboardElement[], ApolloError> => {
  const queryResult = useResultFromQuery(useQuery(GetSprintDashboardDocument));

  return useMemo(() => {
    if (queryResult.state === ResultType.Value) {
      const allSprints = [
        ...queryResult.value.client.activeSprints.map(transformSprintDashboard),
        ...queryResult.value.client.completedSprints.map(transformSprintDashboard),
      ];

      return {
        ...queryResult,
        value: allSprints,
      };
    }

    return queryResult;
  }, [queryResult]);
};

/**
 * Hook to get all accounts for a reef client. If an account has "churned", we
 * will filter it from the list of client accounts for now.
 * @returns all accounts for a given client
 */
export const useClientAccounts = (): Result<Account[], ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const clientResult = useResultFromQuery(useQuery(GetClientAccountsDocument));

  const { augmentAPIAccountsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo(() => {
    if (clientResult.state === ResultType.Value) {
      // TODO: remove this filter
      const clientAccounts = clientResult.value.client.accounts.filter(
        (a) => a.status !== AccountStatus.Churned,
      );
      return {
        ...clientResult,
        value: (shouldAugmentData
          ? augmentAPIAccountsWithDemoData(clientAccounts)
          : clientAccounts
        ).map((a) => getAccountFromAPIAccount(a, currencyCode)),
      };
    }

    return clientResult;
  }, [augmentAPIAccountsWithDemoData, clientResult, currencyCode, shouldAugmentData]);
};

/**
 * Hook to get status for a reef client.
 * @returns status for a given client
 */
export const useClientStatus = (): Result<ClientStatus, ApolloError> => {
  const clientResult = useResultFromQuery(useQuery(GetClientStatusDocument));

  return useMemo(() => {
    if (clientResult.state === ResultType.Value) {
      return {
        ...clientResult,
        value: clientResult.value.client.status,
      };
    }

    return clientResult;
  }, [clientResult]);
};

export const transformAction = <A extends ActionFieldsFragment>(a: A) =>
  ({
    id: a.id,
    title: a.title,
    rank: a.rank,
    author: a.createdBy?.name ?? 'Reef.ai',
    lastUpdated: transformDate(a.lastUpdatedAt, { localized: true }),
    links: a.links.map((l) => ({ id: l.id, title: l.title, url: l.url }) satisfies Link),
    isPublished: a.isPublished,
  }) satisfies Action;

interface UseObjectiveActionOptions {
  objectiveId?: UUID;
  includeUnpublishedActions?: boolean;
  skip?: boolean;
}

export const useObjectiveActions = ({
  objectiveId,
  includeUnpublishedActions = false,
  skip = false,
}: UseObjectiveActionOptions): Result<Action[], ApolloError> => {
  const result = useResultFromQuery(
    useQuery(GetObjectiveActionsDocument, {
      variables: objectiveId != null ? { id: objectiveId } : undefined,
      skip,
    }),
  );

  return useMemo(() => {
    if (result.state === ResultType.Value) {
      if (result.value.node.__typename !== 'Objective') {
        return { state: ResultType.NoValue, value: undefined };
      }

      const value = orderBy(result.value.node.actions, 'rank', 'asc').map(transformAction);

      if (includeUnpublishedActions) {
        return { ...result, value };
      }

      return { ...result, value: value.filter((a) => a.isPublished) };
    }

    return result;
  }, [includeUnpublishedActions, result]);
};

export const useObjectives = (
  {
    withActions,
  }: {
    withActions?: boolean;
  } = { withActions: false },
): Result<Objective[], ApolloError> => {
  const result = useResultFromQuery(useQuery(GetClientObjectivesDocument));

  return useMemo((): Result<Objective[], ApolloError> => {
    if (result.state === ResultType.Value) {
      // order by rank and transform to the UI model
      const value = orderBy(result.value.client.objectives, 'rank', 'asc').map((o): Objective => {
        const objective: Objective = {
          id: o.id,
          title: o.title,
          rank: o.rank,
          hasActions: o.actions.length > 0,
          maxActionRank: o.actions.length > 0 ? Math.max(...o.actions.map(({ rank }) => rank)) : 0,
        };

        if (withActions) {
          objective.actions = o.actions;
        }

        return objective;
      });

      return { ...result, value };
    }

    return result;
  }, [result, withActions]);
};
export const transformClientConfig = (
  config: GetClientConfigQuery['client']['config'] | undefined,
): ClientConfig => {
  if (config) {
    const {
      buckets: {
        healthscoreSegments,
        customerRevenueSegments,
        riskScoreSegments,
        growthScoreSegments,
      },
      currencyCodes,
      defaultBusinessCurrencyCode,
      customerRegions,
    } = config;
    return {
      currencyCodes,
      defaultBusinessCurrencyCode,
      customerRegions,
      buckets: {
        customHealthscore: {
          metricLabel: 'Healthscore',
          ...healthscoreSegments,
        },
        customerRevenueBand: {
          metricLabel: 'Revenue Band',
          ...customerRevenueSegments,
        },
        risk: {
          metricLabel: 'Risk Score',
          ...riskScoreSegments,
        },
        growth: {
          metricLabel: 'Growth Score',
          ...growthScoreSegments,
        },
      },
    };
  }
  return EMPTY_CLIENT_CONFIG;
};
export const useClientConfig = (): [ClientConfig, boolean, Error | undefined] => {
  const [ctx] = useReefAuthContext();
  const result = useQuery(GetClientConfigDocument, { skip: ctx.status !== AuthStatus.SignedIn });
  const { augmentAPIClientConfigWithDemoData, shouldAugmentData } = useDemoAugmenters();
  const configResult = shouldAugmentData
    ? augmentAPIClientConfigWithDemoData(result.data)
    : result.data;
  return useMemo(
    () => [transformClientConfig(configResult?.client.config), result.loading, result.error],
    [configResult, result.error, result.loading],
  );
};
