import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { DOMAIN_REGEX, EMAIL_REGEX } from '../../constants';
import { useReefAuthService } from '../../contexts/reefAuthContext';
import { UpdateSkeletonUserDocument } from '../../graphql/generated';

/**
 * Check if a string is an email.
 * @param value string to check
 * @returns true if the value is an email
 */
function isEmail(value: string): boolean {
  return EMAIL_REGEX.test(value);
}

/**
 * Check if a string is a domain.
 * @param value string to check
 * @returns true if the value is a domain
 */
function isDomain(value: string): boolean {
  return DOMAIN_REGEX.test(value);
}

interface DisguiseSelfProps {
  open: boolean;
  onClose(): void;
}
export const DisguiseSelfDialog = ({ open, onClose }: DisguiseSelfProps) => {
  const auth = useReefAuthService();
  const params = useParams();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [errorMessage, setError] = useState<string>();
  const [updateSkeletonUser] = useMutation(UpdateSkeletonUserDocument);
  const [loading, setLoading] = useState(false);

  const impersonationEmail = useMemo(() => {
    if (isEmail(email)) {
      return email;
    }
    if (isDomain(email)) {
      return `Reef.ai@${email}`;
    }
    return null;
  }, [email]);

  const onImpersonate = useCallback(
    async (email: string) => {
      setLoading(true);
      try {
        // update the api skeleton cache, force pre-token-gen to fire, and reload the window
        await updateSkeletonUser({ variables: { email } });
        await auth.forceRefreshSession();
        const disguiseLandingPath = isEmpty(params)
          ? window.location.href
          : window.location.href.replace(location.pathname, '/');
        window.open(disguiseLandingPath, '_self');
        onClose();
      } catch (err: unknown) {
        setLoading(false);
        console.error('Got an unexpected impersonation error', err);
        setError(`Failed to skeleton user "${email}".`);
      } finally {
        setLoading(false);
      }
    },
    [auth, location, onClose, params, updateSkeletonUser],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited() {
          setEmail('');
          setError(undefined);
          setLoading(false);
        },
      }}
    >
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText variant="h5">Disguise self</DialogContentText>
          <DialogContentText sx={{ display: 'flex' }}>
            Preview Reef.ai as one of it&apos;s users or clients.
          </DialogContentText>
          <TextField
            size="small"
            label="Email or Domain"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            color="primary"
          />
          {errorMessage != null ? <Alert color="error">{errorMessage}</Alert> : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ my: 'auto' }}
          size="small"
          variant="contained"
          disabled={impersonationEmail == null}
          onClick={() => impersonationEmail != null && onImpersonate(impersonationEmail)}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
