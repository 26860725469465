import { format, type Locale as DateFnsLocale } from 'date-fns';
import type React from 'react';
import { createContext, useContext } from 'react';

import type { SignificantDigits } from '../utils';

interface DateLocalization {
  locale: DateFnsLocale | undefined;
  format: typeof format;
}

interface AdminSettings {
  isImpersonating: boolean;
  showDemoData: boolean;
  setShowDemoData: React.Dispatch<React.SetStateAction<boolean>>;
  showUserSwitcher: boolean;
  setShowUserSwitcher: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UserSettingsContextType {
  dates: DateLocalization;
  currencyCode: string;
  currencyFormatter: Intl.NumberFormat;
  currencySymbol: string;
  isUsingDefaultBusinessCurrencyCode: boolean;
  formatCurrencyShort(amount: number | null, significantDigits?: SignificantDigits): string;
  admin: AdminSettings;
}

export const DEFAULT_CURRENCY_NUMBER_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0, // prefer whole numbers
  maximumFractionDigits: 0, // prefer whole numbers
  style: 'currency',
  currency: 'USD',
};
export const DEFAULT_SHORT_CURRENCY_NUMBER_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  notation: 'compact',
  maximumSignificantDigits: 3,
};
const DEFAULT_CURRENCY_FORMATTER = new Intl.NumberFormat(
  'en-US',
  DEFAULT_CURRENCY_NUMBER_FORMAT_OPTIONS,
);
const DEFAULT_SHORT_CURRENCY_FORMATTER = new Intl.NumberFormat(
  'en-US',
  DEFAULT_SHORT_CURRENCY_NUMBER_FORMAT_OPTIONS,
);
export const UserSettingsContext = createContext<UserSettingsContextType>({
  dates: { locale: undefined, format },
  currencyCode: 'USD',
  currencyFormatter: DEFAULT_CURRENCY_FORMATTER,
  currencySymbol: '$',
  isUsingDefaultBusinessCurrencyCode: false,
  formatCurrencyShort: (amount) => (amount ? DEFAULT_SHORT_CURRENCY_FORMATTER.format(amount) : '–'),
  admin: {
    isImpersonating: false,
    showDemoData: false,
    setShowDemoData: () => null,
    showUserSwitcher: false,
    setShowUserSwitcher: () => null,
  },
});

export const useUserSettingsContext = () => useContext(UserSettingsContext);
export const useUserDateSettings = () => useContext(UserSettingsContext).dates;
