import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

import { userWork$ } from '../../../../selectors';

export const LoadingRecs = () => (
  <Box
    sx={{
      height: 'calc(100vh - 8rem)',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack spacing={1} direction="row" data-uid={userWork$.recommendations.loader}>
      <CircularProgress size={24} />
      <Typography variant="overline">Loading Recommendations...</Typography>
    </Stack>
  </Box>
);
