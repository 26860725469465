import { type AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';
import React, { lazy, Suspense } from 'react';

/**
 * Basic wrapper for launch darkly's `LDProvider`.
 * @param props ld provider config
 * @param props.children passed to LDProvider
 * @returns provider component
 */
export const ReefLDProvider = ({ children, ...config }: PropsWithChildren<AsyncProviderConfig>) => {
  // using `asyncWithLDProvider` ensures we first get the configs from launch darkly before
  // rendering the rest of the application
  // for flags like `apolloDevToolsEnabled`, we need to ensure that the flags are loaded _before_
  // mount so that we bootstrap the apollo client correctly
  // https://docs.launchdarkly.com/sdk/client-side/react/react-web#initialize-the-client
  const LDProvider = lazy(async () => ({ default: await asyncWithLDProvider(config) }));
  return (
    // TODO: include a fallback
    <Suspense>
      <LDProvider>{children}</LDProvider>
    </Suspense>
  );
};
