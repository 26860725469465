import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';

import type {
  ArrAtLeastFilter,
  ArrAtMostFilter,
  PipelineAtLeastFilter,
  PipelineAtMostFilter,
} from '../../../../models/filters';
import { AccountFilterType } from '../../../../models/filters';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

interface NumberBetweenFilterInputProps {
  filters: Array<PipelineAtLeastFilter | PipelineAtMostFilter | ArrAtLeastFilter | ArrAtMostFilter>;
  disabled?: boolean;
  loading: boolean;
  onChange(options: { lower: number | undefined; upper: number | undefined } | undefined): void;
}
export const NumberBetweenFilterInput = ({
  filters,
  onChange,
  disabled,
  loading,
}: NumberBetweenFilterInputProps) => {
  const [lower, setLower] = useState<number>();
  const [upper, setUpper] = useState<number>();

  // there can only be up two filters for now - a lower and upper bound where either of them are nullable
  // this will likely change when we introduce the `any` clause
  const lowerBound = useMemo(
    () =>
      filters.find((f): f is PipelineAtLeastFilter | ArrAtLeastFilter =>
        [AccountFilterType.PipelineAtLeast, AccountFilterType.ArrAtLeast].includes(f.type),
      ),
    [filters],
  );
  const upperBound = useMemo(
    () =>
      filters.find((f): f is PipelineAtMostFilter | ArrAtMostFilter =>
        [AccountFilterType.PipelineAtMost, AccountFilterType.ArrAtMost].includes(f.type),
      ),
    [filters],
  );

  const sortedFilters = useMemo(
    // we want to show the lower bound filter on the left
    () => [lowerBound, upperBound].filter((f): f is NonNullable<typeof f> => f != null),
    [lowerBound, upperBound],
  );

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} useFlexGap>
        <Stack direction="row" spacing={1}>
          <TextField
            data-uid={filterCustomers$.editFilter.numberBetween.minInput}
            type="number"
            size="small"
            label="Min"
            disabled={disabled}
            value={lower ?? ''}
            onChange={(e) =>
              setLower(e.target.value.length > 0 ? Number(e.target.value) : undefined)
            }
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>&ndash;</Box>
          <TextField
            data-uid={filterCustomers$.editFilter.numberBetween.maxInput}
            type="number"
            size="small"
            label="Max"
            disabled={disabled}
            value={upper ?? ''}
            onChange={(e) =>
              setUpper(e.target.value.length > 0 ? Number(e.target.value) : undefined)
            }
          />
        </Stack>
        <LoadingButton
          data-uid={filterCustomers$.editFilter.numberBetween.saveBtn}
          sx={{ my: 'auto' }}
          variant="contained"
          size="small"
          color="primary"
          disabled={disabled || (lower == null && upper == null)}
          startIcon={<AddIcon />}
          onClick={() => {
            onChange({ lower, upper });
            setLower(undefined);
            setUpper(undefined);
          }}
          loading={loading}
        >
          Add
        </LoadingButton>
      </Stack>
      <FilterStack
        filters={sortedFilters}
        onDeleteFilter={(i) => {
          switch (sortedFilters[i].type) {
            case AccountFilterType.ArrAtLeast:
            case AccountFilterType.PipelineAtLeast:
              onChange({
                lower: undefined,
                upper: upperBound?.value,
              });
              break;
            case AccountFilterType.ArrAtMost:
            case AccountFilterType.PipelineAtMost:
              onChange({
                upper: undefined,
                lower: lowerBound?.value,
              });
              break;
          }
        }}
      />
    </Stack>
  );
};
