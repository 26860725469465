import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

interface menuOptionType {
  title: string;
  redirectUrl: string;
  'data-uid': string;
  disabled?: boolean;
}

interface MenuButtonProps {
  title: string;
  options: menuOptionType[];
  'data-uid': string;
}

export const MenuButton = ({ title, options, 'data-uid': dataUid }: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const nav = useNavigate();
  const handleMenuOptionClick = (url: string) => {
    setAnchorEl(null);
    nav(url);
  };

  return (
    <div>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        data-uid={dataUid}
      >
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: anchorEl ? anchorEl.offsetWidth : '164px',
            marginTop: '5px',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={`${option.title}`}
            onClick={() => handleMenuOptionClick(option.redirectUrl)}
            disableRipple
            data-uid={option['data-uid']}
            disabled={option.disabled}
          >
            <Typography variant="body1">{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
