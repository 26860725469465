export const COLLAPSED_WIDTH = 70;
export const EXPANDED_WIDTH = 170;

export enum ReefNavTab {
  Analytics = '/analytics',
  Data = '/data',
  Filters = '/filter-customers',
  Sprints = '/sprint',
  Work = '/work',
}
