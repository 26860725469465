import { useAuthenticatedKnockClient } from '@knocklabs/react';
import { useMemo } from 'react';

import { AuthStatus, useReefAuthContext } from '../contexts/reefAuthContext';
import { useCurrentUserDetails } from './user';

/**
 * Wrapper hook for capturing the Knock (notification workflows)
 * keys and instantiated client.
 * @returns object containing `knockKeys` and `knockClient`
 */
export const useKnock = () => {
  const { user } = useCurrentUserDetails();
  const [authCtx] = useReefAuthContext();
  const userToken = useMemo(
    () => (authCtx.status === AuthStatus.SignedIn ? authCtx.session.knockToken : undefined),
    [authCtx],
  );
  // TODO(REEF-1539): move this to centralized process spot
  const knockKeys = {
    apiKey: import.meta.env.VITE_KNOCK_PUBLIC_API_KEY ?? 'MISSING_KNOCK_PUBLIC_API_KEY',
    feedId: import.meta.env.VITE_KNOCK_FEED_ID ?? 'MISSING_KNOCK_FEED_ID',
  };
  const knockClient = useAuthenticatedKnockClient(knockKeys.apiKey, user.id, userToken);
  return {
    knockKeys,
    knockClient,
  };
};
