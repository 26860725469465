import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PersistenceKeys, usePersistenceContext } from '../contexts/persistenceContext';

// FIXME: revisit hook input params shape as singular obj props
type parserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => T;
    };

type setterFn = <U>(prevState: U) => U;

/**
 * Lifted from https://github.com/streamich/react-use/blob/325f5bd69904346788ea981ec18bfc7397c611df/src/useLocalStorage.ts
 * @param key local storage key string
 * @param defaultValue default value to return if no value is found
 * @param options parser options
 * @returns tuple containing current stored value, setter fn to update local storage, and a removal fn
 */
export const useLocalStorage = <T>(
  key: string,
  defaultValue?: T,
  options?: parserOptions<T>,
): [T | undefined, Dispatch<SetStateAction<T>>, () => void] => {
  const deserializer = useMemo(
    () => (options ? (options.raw ? (value: string) => value : options.deserializer) : JSON.parse),
    [options],
  );

  const initializer = useRef((key: string) => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      } else {
        return defaultValue;
      }
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return defaultValue;
    }
  });

  const [state, setState] = useState<T | undefined>(() => initializer.current(key));

  useEffect(() => setState(initializer.current(key)), [key]);

  const set: Dispatch<SetStateAction<T>> = useCallback(
    (valOrFunc) => {
      // cannot satisfy setterFn due to Function guard resolution
      const newState = typeof valOrFunc === 'function' ? (valOrFunc as setterFn)(state) : valOrFunc;
      if (newState == null) {
        return;
      }
      let value: string;

      try {
        if (options) {
          if (options.raw) {
            if (typeof newState === 'string') {
              value = newState;
            } else {
              value = JSON.stringify(newState);
            }
          } else if (options.serializer) {
            value = options.serializer(newState);
          } else {
            value = JSON.stringify(newState);
          }
        } else {
          value = JSON.stringify(newState);
        }

        localStorage.setItem(key, value);
        setState(deserializer(value));
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [deserializer, key, options, state],
  );

  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setState]);

  return [state, set, remove];
};

export const usePersistence = <V>(
  key: PersistenceKeys,
  initialValue: V,
): [V, Dispatch<SetStateAction<V>>] => {
  const { getStorageKey, setChanges, registerReset } = usePersistenceContext();
  const [value, originalSetValue, originalRemove] = useLocalStorage<V>(getStorageKey(key));
  const [, setStorageUpdatedAtValue] = useLocalStorage(
    getStorageKey(PersistenceKeys.StorageUpdatedAt),
    new Date().getTime(),
  );
  const updateChanges = useCallback(
    () => setChanges(key, value == null ? 0 : 1),
    [key, setChanges, value],
  );
  const setValue = useCallback<typeof originalSetValue>(
    (v) => {
      originalSetValue(v);
      setStorageUpdatedAtValue(new Date().getTime());
    },
    [originalSetValue, setStorageUpdatedAtValue],
  );
  const registerResetFn = useCallback(
    () => registerReset(originalRemove),
    [registerReset, originalRemove],
  );

  useEffect(() => {
    registerResetFn();
  }, [registerResetFn]);
  useEffect(() => {
    updateChanges();
  }, [updateChanges]);
  return [value ?? initialValue, setValue];
};
