import type { ObjValues } from '../types/util';

const leftMenuDynamicItemMenu = (name?: string) =>
  `nav-dynamic-list-of-customers-menu-${name ?? ''}` as const;
const leftMenuIndustryItemMenu = (name?: string) =>
  `nav-industry-list-of-customers-menu-${name ?? ''}` as const;

const work = { button: 'work-nav' as const, icon: 'work-nav-icon' as const };
const sprints = { button: 'sprints-nav' as const, icon: 'sprints-nav-icon' as const };
const lists = {
  button: 'lists-nav' as const,
  icon: 'lists-nav-icon' as const,
  leftMenuDynamicItemBtn: 'dynamic-list-button' as const,
  leftMenuDynamicItemMenu,
  leftMenuIndustryItemBtn: 'industry-list-button' as const,
  leftMenuIndustryItemMenu,
};
const analytics = {
  button: 'analytics-nav',
  icon: 'analytics-nav-icon',
  renewalsSubNav: 'renewals-sub-nav-link',
  riskSubNav: 'risk-sub-nav-link',
  growthSubNav: 'growth-sub-nav-link',
} as const;
const filters = {
  button: 'filters-nav' as const,
  icon: 'filters-nav-icon' as const,
  stringFilterInput: 'string-filter-input' as const,
  filterSelection: 'filter-selection' as const,
  filterSelectionLabel: 'filter-selection-label' as const,
};
const data = { button: 'data-nav' as const, icon: 'data-nav-icon' as const };
const common = { collapseSubNav: 'close-subnav-button' as const };
const user = { button: 'user-button' as const, avatar: 'user-button-avatar' as const };
const impersonate = { button: 'impersonate-user-button' } as const;

export const nav$ = { sprints, lists, filters, analytics, data, common, user, impersonate, work };
export type Nav$Values =
  | ObjValues<typeof sprints>
  | ObjValues<typeof lists>
  | ObjValues<typeof analytics>
  | ObjValues<typeof data>
  | ObjValues<typeof common>
  | ObjValues<typeof user>
  | ObjValues<typeof impersonate>
  | ObjValues<typeof work>;
