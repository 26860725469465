import { Alert, Box, Stack } from '@mui/material';
import React from 'react';

import { userWork$ } from '../../../../selectors';

export const EmptyRecs = () => (
  <Box
    sx={{
      height: 'calc(100vh - 8rem)',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <Stack spacing={1} direction="row">
      <Alert data-uid={userWork$.recommendations.empty} severity="success">
        No recommendations from Reef for you!
      </Alert>
    </Stack>
  </Box>
);
