import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';
import { useMemo } from 'react';

export const useCustomTheme = () =>
  useMemo(
    () =>
      createTheme({
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                fontWeight: 300,
              },
            },
          },
          // we want our containers to be more left aligned/oriented, so we
          // override the `margin-left: auto` property
          MuiContainer: {
            styleOverrides: {
              root: {
                marginLeft: '3rem',
              },
            },
          },
          // accordion details comes with `padding: 0.5rem 1rem 1rem` and we want
          // the spacing to be more consistent
          MuiAccordionDetails: {
            styleOverrides: {
              root: {
                padding: '0.5rem',
              },
            },
          },
          // tooltips by default have a dark background color and have no box shadow
          // this updates those styles to fit our design system better
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: '#fff',
                color: '#333',
                // mui doesn't support elevation for tooltips out of the box
                boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              },
            },
          },
        },
        spacing: (factor: number | string) =>
          typeof factor === 'number' ? `${0.5 * factor}rem` : factor,
        palette: {
          primary: {
            main: '#4396f3',
            contrastText: '#fff',
            dark: '#3358B9',
            light: '#95C7FF',
            s4p: '#4396f30A',
            s8p: '#4396f314',
            s12p: '#4396f31F',
            s30p: '#4396f34D',
            s50p: '#4396f380',
          },
          secondary: {
            main: '#37d2e3',
            contrastText: '#fff',
            dark: '#19A3B4',
            light: '#80EDF9',
          },
          error: {
            main: '#FF6331',
            dark: '#e65100',
            contrastText: '#fff',
          },
          success: {
            main: '#70D950',
            dark: '#3c9f1f',
            contrastText: '#fff',
          },
          text: {
            primary: '#333',
            secondary: '#818181',
            disabled: '#b9b9b9',
          },
          magic: {
            reefCallout: {
              main: '#630fe3',
            },
            listHeader: {
              main: '#2579D9',
            },
            drawerBackground: {
              main: '#fbfbfb',
            },
            connectorListBackground: {
              main: '#f6f7fb',
            },
            followSprintButtonBackground: {
              main: '#f8d978',
              dark: '#f3c020',
            },
          },
          warning: {
            main: '#ff9800',
            dark: '#ed6c02',
            s4p: '#ED6C020A',
            s8p: '#ED6C0214',
            s12p: '#ED6C021F',
            s30p: '#ED6C024D',
            s50p: '#ED6C0280',
          },
          info: {
            main: '#7C62DE',
          },
        },
      }),
    [],
  );
