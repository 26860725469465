import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';

import type { NotHasProductFilter, ProductOverageAtLeastFilter } from '../../../../models/filters';
import { AccountFilterType } from '../../../../models/filters';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

interface ProductFilterInput {
  options: string[];
  loading: boolean;
  disabled?: boolean;
}
interface NotHasProductFilterInputProps extends ProductFilterInput {
  type: AccountFilterType.NotHasProduct;
  filters: NotHasProductFilter[];
  onChange(filters: Omit<NotHasProductFilter, 'id'>[]): void;
}
interface ProductOverageAtLeastFilterInputProps extends ProductFilterInput {
  type: AccountFilterType.ProductOverageAtLeast;
  filters: ProductOverageAtLeastFilter[];
  onChange(filters: Omit<ProductOverageAtLeastFilter, 'id'>[]): void;
}
export const ProductFilterInput = ({
  type,
  options,
  disabled,
  loading,
  filters,
  onChange,
}: ProductOverageAtLeastFilterInputProps | NotHasProductFilterInputProps) => {
  const productInputRef = useRef<HTMLInputElement>();
  const [productName, setProductName] = useState<string>();
  const [overageAmount, setOverageAmount] = useState<number>();

  const onAddFilter = useCallback(
    (productName: string | undefined, value: number | undefined) => {
      if (productName != null) {
        switch (type) {
          case AccountFilterType.NotHasProduct:
            onChange([...filters, { type, productName }]);
            break;
          case AccountFilterType.ProductOverageAtLeast:
            if (value != null) {
              onChange([...filters, { type, productName, value }]);
            }
            break;
        }
      }
      setProductName(undefined);
      setOverageAmount(undefined);
      productInputRef.current?.focus();
    },
    [filters, onChange, type],
  );

  const onDeleteFilter = useCallback(
    (i: number) => {
      switch (type) {
        case AccountFilterType.NotHasProduct:
          onChange([...filters.slice(0, i), ...filters.slice(i + 1)]);
          break;
        case AccountFilterType.ProductOverageAtLeast:
          onChange([...filters.slice(0, i), ...filters.slice(i + 1)]);
          break;
      }
    },
    [filters, onChange, type],
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} useFlexGap>
        <Autocomplete
          fullWidth
          disabled={disabled}
          size="small"
          options={options ?? []}
          freeSolo
          loading={loading}
          value={productName ?? null}
          onChange={(_, newValue) => newValue != null && setProductName(newValue)}
          isOptionEqualToValue={(option, value) => option.toLowerCase() === value.toLowerCase()}
          renderInput={(params) => (
            <TextField
              {...params}
              data-uid={filterCustomers$.editFilter.product.nameInput}
              InputProps={{ ...params.InputProps, startAdornment: <SearchIcon /> }}
              inputRef={productInputRef}
              label="Search Products"
            />
          )}
        />
        <Box sx={{ display: 'flex', my: 'auto' }}>
          <LoadingButton
            data-uid={filterCustomers$.editFilter.product.saveBtn}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            disabled={
              disabled ||
              productName == null ||
              (type === AccountFilterType.ProductOverageAtLeast && overageAmount == null)
            }
            onClick={() => onAddFilter(productName, overageAmount)}
            loading={loading}
          >
            Add
          </LoadingButton>
        </Box>
      </Stack>
      {type === AccountFilterType.ProductOverageAtLeast && (
        <Box sx={{ width: '70%' }}>
          <TextField
            data-uid={filterCustomers$.editFilter.product.overageInput}
            type="number"
            label="Overage $ at least"
            size="small"
            disabled={disabled}
            fullWidth
            value={overageAmount ?? ''}
            onChange={(e) => setOverageAmount(parseInt(e.target.value, 10))}
          />
        </Box>
      )}
      <FilterStack filters={filters} onDeleteFilter={onDeleteFilter} />
    </Stack>
  );
};
