const Brand = Symbol();

/**
 * A branded nominal type.
 */
export type Nominal<K, T extends string> = K & { [Brand]: T };

/**
 * A UUID type branded string. Used to identify Reef entities.
 */
export type UUID = Nominal<string, 'UUID'>;

/**
 * Simple caster to use strings as UUID values.
 * @param v string value, presumably a valid UUID value.
 * @returns UUID value
 * @example
 *    const someUUID: UUID = asUUID('unexpected-banana-id');
 */
export const asUUID = (v: string): UUID => v as UUID;
