import { Box } from '@mui/material';
import React from 'react';
import type { OutletProps } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { ReefNavDrawer } from '../ReefNavDrawer';
import { COLLAPSED_WIDTH } from '../ReefNavDrawer/navUtils';

export const ReefLeftNavOutlet = (props: OutletProps) => (
  <>
    <ReefNavDrawer />
    <Box sx={{ width: `calc(100% - ${COLLAPSED_WIDTH}px)`, ml: `${COLLAPSED_WIDTH}px` }}>
      <Outlet {...props} />
    </Box>
  </>
);
