import { useMutation } from '@apollo/client';
import { Home } from '@mui/icons-material';
import { Accordion, Box, LinearProgress, List, ListItemButton, Typography } from '@mui/material';
import { formatDistanceToNow, isFuture } from 'date-fns';
import React, { useCallback } from 'react';

import { GetMySprintsDocument, UnfollowSprintDocument } from '../../../graphql/generated';
import { useSprintList } from '../../../hooks/sprint';
import { ResultType } from '../../../models/result';
import type { SprintNavElement } from '../../../models/sprint';
import type { UUID } from '../../../models/uuid';
import { FailedResult } from '../../Alerts';
import { ListSectionHeader } from '../ListSectionHeader';
import { SprintListMenuItem } from './SprintListMenuItem';

interface SprintListMenuProps {
  /**
   * The current selected sprint.
   */
  selectedSprint: UUID | undefined;
  /**
   * Called when a sprint is clicked in the list menu.
   * @param id the sprint that is selected from the menu
   */
  onSelectSprint(id: UUID): void;
  /**
   * Called when the sprint dashboard element is selected in the menu.
   */
  onSelectSprintDashboard(): void;
}

export const SprintListMenu = ({
  selectedSprint,
  onSelectSprint,
  onSelectSprintDashboard,
}: SprintListMenuProps) => {
  const sprintsResult = useSprintList();
  const [unfollowSprint] = useMutation(UnfollowSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }],
  });

  const getListItemCreatedTitle = useCallback((sprint: SprintNavElement) => {
    if (sprint.start != null) {
      return isFuture(sprint.start)
        ? `Will start in ${formatDistanceToNow(sprint.start)}`
        : `Started ${formatDistanceToNow(sprint.start)} ago`;
    }
    return;
  }, []);

  const getListItemEndedTitle = useCallback((sprint: SprintNavElement) => {
    if (sprint.end != null) {
      return isFuture(sprint.end)
        ? `Ends in ${formatDistanceToNow(sprint.end)}`
        : `Completed ${formatDistanceToNow(sprint.end)} ago`;
    }
    return;
  }, []);

  if (sprintsResult.state === ResultType.Loading) {
    return <LinearProgress />;
  }

  if (sprintsResult.state === ResultType.Error || sprintsResult.state === ResultType.NoValue) {
    return <FailedResult resultType="sprints" error={sprintsResult.value} />;
  }

  const { created, following, completedSprints, followingCompletedSprints } = sprintsResult.value;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <List disablePadding>
        <ListItemButton
          disableGutters
          sx={{
            py: 1.5,
            px: 2,
            bgcolor: (theme) => theme.palette.secondary.dark,
            color: (theme) => theme.palette.secondary.contrastText,
            '&:hover': {
              bgcolor: (theme) => theme.palette.primary.s12p,
              color: (theme) => theme.palette.primary.dark,
            },
          }}
          onClick={() => onSelectSprintDashboard()}
        >
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ my: 'auto' }}>
              <Typography>Sprint Dashboard</Typography>
            </Box>
            <Home />
          </Box>
        </ListItemButton>
        <Accordion elevation={0} disableGutters defaultExpanded square>
          <ListSectionHeader title="My Sprints" />
          {created.map((sprint) => (
            <SprintListMenuItem
              key={sprint.id}
              title={getListItemCreatedTitle(sprint)}
              selected={sprint.id === selectedSprint}
              sprintName={sprint.name}
              onClick={() => onSelectSprint(sprint.id)}
            />
          ))}
        </Accordion>
        <Accordion elevation={0} disableGutters defaultExpanded square>
          <ListSectionHeader title="Following" />
          {following.map((sprint) => (
            <SprintListMenuItem
              key={sprint.id}
              title={getListItemCreatedTitle(sprint)}
              selected={sprint.id === selectedSprint}
              sprintName={sprint.name}
              onClick={() => onSelectSprint(sprint.id)}
              onUnfollowSprint={() => unfollowSprint({ variables: { sprintId: sprint.id } })}
            />
          ))}
        </Accordion>
        <Accordion elevation={0} disableGutters defaultExpanded square>
          <ListSectionHeader title="Completed Sprints" />
          {completedSprints.map((sprint) => (
            <SprintListMenuItem
              key={sprint.id}
              title={getListItemEndedTitle(sprint)}
              selected={sprint.id === selectedSprint}
              sprintName={sprint.name}
              onClick={() => onSelectSprint(sprint.id)}
            />
          ))}
          {followingCompletedSprints.map((sprint) => (
            <SprintListMenuItem
              key={sprint.id}
              title={getListItemEndedTitle(sprint)}
              selected={sprint.id === selectedSprint}
              sprintName={sprint.name}
              onClick={() => onSelectSprint(sprint.id)}
              onUnfollowSprint={() => unfollowSprint({ variables: { sprintId: sprint.id } })}
            />
          ))}
        </Accordion>
      </List>
    </Box>
  );
};
