import React, { useMemo } from 'react';
import type { OutletProps } from 'react-router-dom';
import { Outlet, useMatch } from 'react-router-dom';

import type { SplashPageProps } from '../Routes/SplashPage';
import { SplashPage } from '../Routes/SplashPage';

export const SplashPageOutlet = (props: OutletProps) => {
  const loginMatch = useMatch('login');
  const signUpMatch = useMatch('sign-up');
  const challengeMatch = useMatch('sign-up/challenge');
  const resendCodeMatch = useMatch('sign-up/resend-code');
  const skeletonMatch = useMatch('skeleton');
  const knockMatch = useMatch('skeleton/knock');

  const splashPageProps = useMemo((): SplashPageProps => {
    if (loginMatch != null) {
      return {
        title: 'Log In',
        description: (
          <>
            Sign in to your <strong>Reef.ai</strong> account using your work credentials below.
          </>
        ),
      };
    }
    if (challengeMatch != null) {
      return {
        title: 'Verify your email',
        description: 'We have sent a security code to your email address.',
      };
    }
    if (resendCodeMatch != null) {
      return {
        title: 'Resend Security Code',
        description:
          'If you have lost your security code, or it has expired, we will send a new code to your email address.',
      };
    }
    if (signUpMatch != null) {
      return {
        title: 'Sign Up',
        description: (
          <>
            Create your <strong>Reef.ai</strong> account.
          </>
        ),
      };
    }
    if (knockMatch != null) {
      return {
        title: <>☠️ Skeleton sign-up 🗝</>,
        description: (
          <>
            Create your <strong>skeleton</strong> user account in Reef.
          </>
        ),
      };
    }
    if (skeletonMatch != null) {
      return {
        title: <>☠️ Log In 🗝</>,
        description: (
          <>
            Sign in to <em>another</em> <strong>Reef.ai</strong> account using your skeleton
            credentials.
          </>
        ),
      };
    }

    // send an alert if we don't hit the proper routing
    console.error('rendering invalid splash page');
    return { title: '', description: '' };
  }, [challengeMatch, knockMatch, loginMatch, resendCodeMatch, signUpMatch, skeletonMatch]);

  return (
    <SplashPage {...splashPageProps}>
      <Outlet {...props} />
    </SplashPage>
  );
};
