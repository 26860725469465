import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { useUserDateSettings } from './userSettingsContext';

/**
 * Wrapper for MUI's LocalizationProvider.
 * @param param0 props
 * @param param0.children children
 * @returns component
 */
export const MuiLocalizationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { locale } = useUserDateSettings();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
