export const RENEWAL_ANALYTICS_COLORS = {
  Renewed: '#4396F3',
  RenewedOnTime: '#4396F3',
  RenewedLate: '#9CC5F4',
  Upsell: '#80E163',
  ProductCrossSell: '#80E163',
  ProductUpsell: '#80E163',
  Pending: '#96E3EC',
  Churn: '#F94848',
  ProductDownsell: '#F69E9E',
  ProductChurn: '#F69E9E',
  ContractChurn: '#F69E9E',
  TotalExpiring: '#37D2E3',
  TotalRenewed: '#4396F3',
  Healthscore: {
    Red: '#F94848',
    Yellow: '#FED766',
    Green: '#80E163',
    null: '#B9B9B9',
  },
  Outcome: {
    Won: '#37D2E3',
    Lost: '#F94848',
    Unknown: '#B9B9B9',
    NoRenewalFound: '#B9B9B9',
    Active: '#7C62DE',
  },
} as const;

export const MISSING_COLOR = '#b4d455';
