import { Close as CloseIcon } from '@mui/icons-material';
import type { DrawerProps } from '@mui/material';
import { Box, Drawer, IconButton } from '@mui/material';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { nav$ } from '../../selectors';

export interface CollapsibleDrawerProps {
  'data-uid'?: string;
  open: boolean;
  onClose(): void;
  width: number;
  anchor?: DrawerProps['anchor'];
  hideBackdrop?: DrawerProps['hideBackdrop'];
  horizontalOffset: number;
}

export const CollapsibleDrawer = ({
  'data-uid': dataUid,
  open,
  onClose,
  anchor,
  width,
  horizontalOffset,
  hideBackdrop,
  children,
}: PropsWithChildren<CollapsibleDrawerProps>) => (
  <Drawer
    data-uid={dataUid}
    open={open}
    onClose={onClose}
    anchor={anchor}
    hideBackdrop={hideBackdrop}
    sx={{
      width,
      ml: `${horizontalOffset}px`,
      '& .MuiDrawer-paper': {
        width,
        ml: `${horizontalOffset}px`,
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'unset',
      },
    }}
  >
    <Box sx={{ ml: 'auto', my: 1, mr: 1 }}>
      <IconButton data-uid={nav$.common.collapseSubNav} onClick={onClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
    {children}
  </Drawer>
);
