import React, { useEffect } from 'react';
import type { NavigateFunction, OutletProps } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router-dom';

declare global {
  // eslint-disable-next-line no-var
  var cyNavigate: NavigateFunction | undefined;
}

export const RootOutlet = (props: OutletProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    // capture navigate for Cypress testing navigation
    if (window.Cypress) {
      window.cyNavigate = navigate;
    }
  }, [navigate]);

  return <Outlet {...props} />;
};
