import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { AccordionSummary, ListSubheader } from '@mui/material';
import React from 'react';

interface ListSectionHeaderProps {
  title: string;
}

export const ListSectionHeader = ({ title }: ListSectionHeaderProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon color="inherit" />}
    sx={{
      py: 0,
      px: 2,
      backgroundColor: (theme) => theme.palette.magic.listHeader.main,
      color: (theme) => theme.palette.primary.contrastText,

      // TODO: the rest of these styles have some really ugly overrides
      // there has to be a better way to do this - when we find time, lets clean this up
      // it would probably be best to revisit the design for the list nav
      '.MuiAccordionSummary-content': {
        margin: 0,
      },
      '.MuiAccordionSummary-expandIconWrapper': {
        color: (theme) => theme.palette.primary.contrastText,
      },
      '&.Mui-focusVisible': {
        color: (theme) => theme.palette.text.primary,
        '.MuiAccordionSummary-expandIconWrapper': {
          color: (theme) => theme.palette.text.primary,
        },
      },
    }}
  >
    <ListSubheader disableGutters sx={{ bgcolor: 'transparent' }} color="inherit">
      {title}
    </ListSubheader>
  </AccordionSummary>
);
