import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, MenuItem, Stack, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';

import type {
  PrimaryOwnerFilter,
  RenewalOwnerFilter,
  SecondaryOwnerFilter,
} from '../../../../models/filters';
import type { UUID } from '../../../../models/uuid';
import { filterCustomers$ } from '../../../../selectors';
import { FilterStack } from '../FilterStack';

// Default mui autocomplete option structure.
// https://mui.com/material-ui/react-autocomplete/#options-structure
// if you don't use this structure, then it is necessary to provide a `getOptionLabel` prop
interface AutocompleteOption {
  id: UUID;
  label: string;
}

interface OwnershipFilterInputProps<
  T extends PrimaryOwnerFilter | SecondaryOwnerFilter | RenewalOwnerFilter,
> {
  type: T['type'];
  filter: T | undefined;
  disabled?: boolean;
  loading: boolean;
  options: AutocompleteOption[] | undefined;
  onChange(names: string[]): void;
}
export const OwnershipFilterInput = <
  T extends PrimaryOwnerFilter | SecondaryOwnerFilter | RenewalOwnerFilter,
>({
  type,
  filter,
  disabled,
  loading,
  options,
  onChange,
}: OwnershipFilterInputProps<T>) => {
  const inputRef = useRef();
  const [value, setValue] = useState<AutocompleteOption | null>(null);
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} useFlexGap>
        <Autocomplete
          fullWidth
          disabled={disabled}
          size="small"
          options={options ?? []}
          loading={loading}
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              data-uid={filterCustomers$.editFilter.ownership.userOption(type)}
              key={option.id}
            >
              {option.label}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />,
              }}
              inputProps={{
                ...params.inputProps,
                'data-uid': filterCustomers$.editFilter.ownership.searchUsersInput(type),
              }}
              inputRef={inputRef}
              label="Search Owner"
            />
          )}
        />
        <Box sx={{ display: 'flex', my: 'auto' }}>
          <LoadingButton
            data-uid={filterCustomers$.editFilter.ownership.saveBtn(type)}
            variant="contained"
            size="small"
            color="primary"
            disabled={disabled || value == null}
            onClick={() => {
              if (value != null) {
                onChange([...(filter?.owners ?? []), value.label]);
                setValue(null);
              }
            }}
            startIcon={<AddIcon />}
            loading={loading}
          >
            Add
          </LoadingButton>
        </Box>
      </Stack>
      <FilterStack
        filters={filter != null ? [filter] : []}
        onDeleteFilter={(i) => {
          const owners = filter?.owners ?? [];
          onChange([...owners.slice(0, i), ...owners.slice(i + 1)]);
        }}
      />
    </Stack>
  );
};
