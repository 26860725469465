import { Cancel as CancelIcon } from '@mui/icons-material';
import type { StackProps, SxProps, Theme } from '@mui/material';
import { Chip, Stack } from '@mui/material';
import React, { useMemo } from 'react';

import { useUserDateSettings } from '../../../contexts/userSettingsContext';
import type { AccountFilterType, Filter } from '../../../models/filters';
import { filterCustomers$ } from '../../../selectors';
import { getFilterLabels } from '../../Routes/FilterCustomers/utils';

interface FilterStackProps extends StackProps {
  filters: Filter[];
  onClickFilter?(type: AccountFilterType): void;
  onDeleteFilter?(index: number): void;
  sx?: SxProps<Theme>;
}
export const FilterStack = ({
  filters,
  onClickFilter,
  onDeleteFilter,
  ...stackProps
}: FilterStackProps) => {
  const { locale } = useUserDateSettings();
  const filterChips = useMemo(() => getFilterLabels(filters, locale), [filters, locale]);
  return (
    <Stack spacing={1}>
      <Stack {...stackProps} direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {filterChips.length === 0 && (
          <Chip
            data-uid={filterCustomers$.details.filterOperationChip('all')}
            size="small"
            label="All"
          />
        )}
        {filterChips.map((f, i) => {
          return (
            <Chip
              key={i}
              data-uid={filterCustomers$.details.filterOperationChip(f.type)}
              clickable={onClickFilter != null}
              onClick={onClickFilter != null ? () => onClickFilter(f.type) : undefined}
              label={f.label}
              size="small"
              deleteIcon={
                <CancelIcon data-uid={filterCustomers$.details.deleteFilterIcon(f.type)} />
              }
              onDelete={onDeleteFilter != null ? () => onDeleteFilter(i) : undefined}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
