import type { ObjValues } from '../types/util';

const component = 'app-tooltip';
const heatmapTile = 'heatmap-tile-tooltip';
const customGridTooltip = 'custom-grid-tooltip';
const nullScoreChipTooltip = 'null-score-chip-tooltip';
const nullHeatmapAxisTooltip = 'null-heatmap-axis-tooltip';
const nullHeatmapAxisTooltipIcon = 'null-heatmap-axis-tooltip-icon';
interface Tooltip$ {
  component: typeof component;
  heatmapTile: typeof heatmapTile;
  customGridTooltip: typeof customGridTooltip;
  nullScoreChipTooltip: typeof nullScoreChipTooltip;
  nullHeatmapAxisTooltip: typeof nullHeatmapAxisTooltip;
  nullHeatmapAxisTooltipIcon: typeof nullHeatmapAxisTooltipIcon;
}

export const tooltip$: Tooltip$ = {
  component,
  heatmapTile,
  customGridTooltip,
  nullScoreChipTooltip,
  nullHeatmapAxisTooltip,
  nullHeatmapAxisTooltipIcon,
};
export type Tooltip$Values = ObjValues<Tooltip$>;
