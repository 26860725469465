import { useEffect, useState } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
// import types so we can define the interface - this loads these funcs for TS, but not
// into the bundle
import type {
  augmentAPIAccountProductMetricsWithDemoData,
  augmentAPIAccountsWithDemoData,
  augmentAPICaseDetailsFieldsWithDemoData,
  augmentAPIClientConfigWithDemoData,
  augmentAPIContractFieldsWithDemoData,
  augmentAPIOpportunityFieldsWithDemoData,
  augmentAPIProductFieldsWithDemoData,
} from './demo';

interface UseDemoAugmenters {
  augmentAPIAccountsWithDemoData: typeof augmentAPIAccountsWithDemoData;
  augmentAPICaseDetailsFieldsWithDemoData: typeof augmentAPICaseDetailsFieldsWithDemoData;
  augmentAPIClientConfigWithDemoData: typeof augmentAPIClientConfigWithDemoData;
  augmentAPIContractFieldsWithDemoData: typeof augmentAPIContractFieldsWithDemoData;
  augmentAPIProductFieldsWithDemoData: typeof augmentAPIProductFieldsWithDemoData;
  augmentAPIOpportunityFieldsWithDemoData: typeof augmentAPIOpportunityFieldsWithDemoData;
  augmentAPIAccountProductMetricsWithDemoData: typeof augmentAPIAccountProductMetricsWithDemoData;
  /**
   * Flag to indicate if we should augment the data or not.
   */
  shouldAugmentData: boolean;
}

const DEFAULT_DEMO_UTILS: UseDemoAugmenters = {
  augmentAPIAccountsWithDemoData: (data) => data,
  augmentAPICaseDetailsFieldsWithDemoData: (data) => data,
  augmentAPIClientConfigWithDemoData: (data) => data,
  augmentAPIContractFieldsWithDemoData: (data) => data,
  augmentAPIProductFieldsWithDemoData: (data) => data,
  augmentAPIOpportunityFieldsWithDemoData: (data) => data,
  augmentAPIAccountProductMetricsWithDemoData: (data) => data,
  shouldAugmentData: false,
};

/**
 * Hook to use data augmenter functions from `graphql/demo` while preserving code splitting.
 * @returns interface with functions that augment demo data
 */
export function useDemoAugmenters(): UseDemoAugmenters {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const [result, setResult] = useState<UseDemoAugmenters>(DEFAULT_DEMO_UTILS);
  useEffect(() => {
    const loadFunctionState = async () => {
      if (showDemoData) {
        const {
          augmentAPIAccountsWithDemoData,
          augmentAPICaseDetailsFieldsWithDemoData,
          augmentAPIClientConfigWithDemoData,
          augmentAPIContractFieldsWithDemoData,
          augmentAPIProductFieldsWithDemoData,
          augmentAPIOpportunityFieldsWithDemoData,
          augmentAPIAccountProductMetricsWithDemoData,
        } = await import('./demo');
        setResult({
          shouldAugmentData: true,
          augmentAPIAccountsWithDemoData,
          augmentAPICaseDetailsFieldsWithDemoData,
          augmentAPIClientConfigWithDemoData,
          augmentAPIContractFieldsWithDemoData,
          augmentAPIProductFieldsWithDemoData,
          augmentAPIOpportunityFieldsWithDemoData,
          augmentAPIAccountProductMetricsWithDemoData,
        });
      } else {
        setResult(DEFAULT_DEMO_UTILS);
      }
    };
    loadFunctionState();
  }, [showDemoData]);
  return result;
}
