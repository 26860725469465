import type { ApolloError, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { differenceInSeconds } from 'date-fns';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

import type {
  CrossSellGrowthRecommendationPreviewFieldsFragment,
  FullChurnRiskRecommendationPreviewFieldsFragment,
  GetClientContractRecommendationsQuery,
  GetClientGrowthRecommendationsPreviewQuery,
  GetClientRiskRecommendationsPreviewQuery,
  GetMyWorkRecommendationsQuery,
  LegacyContractRecommendationFieldsFragment,
  PartialChurnRiskRecommendationPreviewFieldsFragment,
} from '../graphql/generated';
import {
  GetClientContractRecommendationsDocument,
  GetClientGrowthRecommendationsPreviewDocument,
  GetClientRiskRecommendationsPreviewDocument,
  GetMyWorkRecommendationsDocument,
  GetRecommendationDocument,
  ReefRecommendationStatus,
} from '../graphql/generated';
import type { GrowthRecommendation, RiskRecommendation } from '../models/recommendation';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';
import { asUUID } from '../models/uuid';
import { transformDate } from '../utils';

const transformUserContractRecommendation = <T extends GetMyWorkRecommendationsQuery['self']>(
  item: T extends {
    __typename?: 'User';
    associated: { recommendations: Array<infer U> };
  }
    ? U
    : never,
): RiskRecommendation => ({
  id: item.id,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  account: {
    id: item.account.id,
    name: item.account.name,
    ownerEmail: item.account.roles.primaryOwner?.email ?? null,
    ownerName: item.account.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.roles?.renewalOwner?.name ?? null,
    customerHealthscore: item.account.rawData.customHealthValue ?? null,
  },
  contract: {
    contractEndDate: transformDate(item.contract.endDate),
    contractEndFiscalQuarter: item.contract.endFiscalQuarter.split(' ')[1],
    expiringArr: item.contract.expiringArr,
    id: item.contract.id,
    // TODO: sub with crmLink when available
    link: `/customer-details/${item.account.id}/contracts`,
    name: item.contract.name,
    productNames: item.contract.servicePeriods.map((sp) => sp.productName),
    usageRating: item.contract.usageRating,
  },
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  title: item.playbook.title,
  sprint:
    item.sprint != null
      ? {
          id: item.sprint.id,
          end: transformDate(item.sprint.end),
          name: item.sprint.name,
          start: transformDate(item.sprint.start),
          createdAt: transformDate(item.sprint.createdAt),
          creatorEmail: item.sprint.createdBy.email,
          creatorId: item.sprint.createdBy.id,
          creatorName: item.sprint.createdBy.name,
        }
      : null,
  createdOn: transformDate(item.createdOn),
  metrics: null,
  type: 'RISK',
  expired: item.expired,
});
export const transformUserContractRecommendations = <
  T extends LegacyContractRecommendationFieldsFragment,
>(
  items: T[],
): RiskRecommendation[] => items.map(transformUserContractRecommendation);

const transformFullChurnRiskRecommendationPreview = <
  T extends FullChurnRiskRecommendationPreviewFieldsFragment,
>(
  item: T,
): RiskRecommendation => ({
  account: {
    customerHealthscore: item.account.current?.rawData.customHealthValue ?? null,
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.current?.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.current?.roles.renewalOwner?.name ?? null,
  },
  contract: {
    contractEndDate: null,
    contractEndFiscalQuarter: '', // FIXME: nullable contract end FQ
    expiringArr: null,
    id: asUUID(''), // FIXME: nullable contract id
    link: null,
    name: null,
    productNames: null,
    usageRating: null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current?.bucket ?? null,
          category: item.recommendationScore.current?.category ?? null,
          type: 'ACCOUNT_FULL_CHURN_RISK',
          value: item.recommendationScore.current?.value ?? null,
          color: item.recommendationScore.current?.color ?? null,
        }
      : null,
    originalScore: null, // Preview RiskRec never has original value
    relevantARR: item.relevantArr.current, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'FULL_CHURN_CUSTOMER',
});
const transformPartialChurnRiskRecommendationPreview = <
  T extends PartialChurnRiskRecommendationPreviewFieldsFragment,
>(
  item: T,
): RiskRecommendation => ({
  account: {
    customerHealthscore: item.account.current?.rawData.customHealthValue ?? null,
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.current?.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.current?.roles.renewalOwner?.name ?? null,
  },
  contract: {
    contractEndDate: item.contract.current ? transformDate(item.contract.current?.endDate) : null,
    contractEndFiscalQuarter: item.contract.current?.endFiscalQuarter ?? '', // FIXME: nullable contract end FQ
    expiringArr: item.contract.current?.expiringArr ?? null,
    id: item.contract.current?.id ?? asUUID(''), // FIXME: nullable contract id
    // TODO: sub with crmLink when available
    link: item.account.current ? `/customer-details/${item.account.current.id}/contracts` : null,
    name: item.contract.current?.name ?? null,
    productNames: item.contract.current?.servicePeriods.map((sp) => sp.productName) ?? null,
    usageRating: item.contract.current?.usageRating ?? null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket,
          category: item.recommendationScore.current.category,
          type: 'CONTRACT_USAGE_RATING',
          value: item.recommendationScore.current.value,
          color: item.recommendationScore.current.color,
        }
      : null,
    originalScore: null, // Preview RiskRec never has original value
    relevantARR: item.relevantArr.current, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PARTIAL_CHURN_CONTRACT',
});
const transformUserRiskRecommendations = <
  T extends
    | FullChurnRiskRecommendationPreviewFieldsFragment
    | PartialChurnRiskRecommendationPreviewFieldsFragment,
>(
  items: T[],
): RiskRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefCustomerFullChurnRecommendation':
          return transformFullChurnRiskRecommendationPreview(item);
        case 'ReefContractPartialChurnRecommendation':
          return transformPartialChurnRiskRecommendationPreview(item);
        default:
          return null;
      }
    }),
  );

type ClientContractRecommendationsResult =
  GetClientContractRecommendationsQuery['client']['recommendations'];
const isContractRecommendation = (
  item: ClientContractRecommendationsResult[0],
): item is LegacyContractRecommendationFieldsFragment =>
  item.__typename === 'ReefContractRecommendation';
const extractContractRecommendations = <T extends ClientContractRecommendationsResult>(items: T) =>
  items.filter((item) => isContractRecommendation(item));

type ClientRiskRecommendationsPreviewResult =
  GetClientRiskRecommendationsPreviewQuery['client']['previewRecommendations'];
const isFullChurnRiskRecommendationPreview = (
  item: ClientRiskRecommendationsPreviewResult[0],
): item is FullChurnRiskRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefCustomerFullChurnRecommendation';
const isPartialChurnRiskRecommendationPreview = (
  item: ClientRiskRecommendationsPreviewResult[0],
): item is PartialChurnRiskRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefContractPartialChurnRecommendation';
const extractRiskRecommendationsPreview = <T extends ClientRiskRecommendationsPreviewResult>(
  items: T,
) =>
  items.filter(
    (item) =>
      isFullChurnRiskRecommendationPreview(item) || isPartialChurnRiskRecommendationPreview(item),
  );

const transformCrossSellGrowthRecommendationPreview = <
  T extends CrossSellGrowthRecommendationPreviewFieldsFragment,
>(
  item: T,
): GrowthRecommendation => ({
  account: {
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    customerStartedOn: transformDate(item.account.current?.rawData.customerStartedOn ?? null),
    rawPipeline: item.account.current?.rawData.rawPipeline ?? null,
    renewalDate: transformDate(item.account.current?.rawData.renewalDate ?? null),
    secondaryOwnerEmail: item.account.current?.roles.secondaryOwner?.email ?? null,
    secondaryOwnerName: item.account.current?.roles.secondaryOwner?.name ?? null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentPipeline: null,
    currentPredictedOverage: null,
    currentPredictedOverageDate: null,
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket ?? null,
          category: item.recommendationScore.current.category ?? null,
          type: 'UNKNOWN',
          value: item.recommendationScore.current.value ?? null,
          color: item.recommendationScore.current.color ?? null,
        }
      : null,
    originalPipeline: null,
    originalPredictedOverage: null,
    originalPredictedOverageDate: null,
    originalScore: null, // Preview GrowthRec never has original value
    products: item.product.current ? [item.product.current] : [],
  },
  owner:
    item.owner != null
      ? {
          email: item.owner.email,
          id: item.owner.id,
          name: item.owner.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PRODUCT_CROSS_SELL',
});
const transformUserGrowthRecommendations = <
  T extends CrossSellGrowthRecommendationPreviewFieldsFragment,
>(
  items: T[],
): GrowthRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefProductCrossSellRecommendation':
          return transformCrossSellGrowthRecommendationPreview(item);
        default:
          return null;
      }
    }),
  );
type ClientGrowthRecommendationsPreviewResult =
  GetClientGrowthRecommendationsPreviewQuery['client']['previewRecommendations'];
const isCrossSellGrowthRecommendationPreview = (
  item: ClientGrowthRecommendationsPreviewResult[0],
): item is CrossSellGrowthRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefProductCrossSellRecommendation';
const extractGrowthRecommendationsPreview = <T extends ClientGrowthRecommendationsPreviewResult>(
  items: T,
) => items.filter((item) => isCrossSellGrowthRecommendationPreview(item));

export const useClientRecommendations = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<RiskRecommendation[], ApolloError> => {
  const clientRecommendationsResult = useResultFromQuery(
    useQuery(GetClientContractRecommendationsDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (clientRecommendationsResult.state === ResultType.Value) {
      const { recommendations } = clientRecommendationsResult.value.client;
      const contractRecommendations = extractContractRecommendations(recommendations);
      const value = transformUserContractRecommendations(contractRecommendations).sort((r1, r2) =>
        differenceInSeconds(r2.createdOn ?? 0, r1.createdOn ?? 0),
      );
      return { state: clientRecommendationsResult.state, value };
    }
    return clientRecommendationsResult;
  }, [clientRecommendationsResult]);
};

export const useClientRiskRecommendationsPreview = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<RiskRecommendation[], ApolloError> => {
  const clientRiskRecommendationsPreviewResult = useResultFromQuery(
    useQuery(GetClientRiskRecommendationsPreviewDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (clientRiskRecommendationsPreviewResult.state === ResultType.Value) {
      const { previewRecommendations } = clientRiskRecommendationsPreviewResult.value.client;
      const riskRecommendations = extractRiskRecommendationsPreview(previewRecommendations);
      const value = transformUserRiskRecommendations(riskRecommendations);
      return { state: clientRiskRecommendationsPreviewResult.state, value };
    }
    return clientRiskRecommendationsPreviewResult;
  }, [clientRiskRecommendationsPreviewResult]);
};

export const useClientGrowthRecommendationsPreview = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<GrowthRecommendation[], ApolloError> => {
  const clientGrowthRecommendationsPreviewResult = useResultFromQuery(
    useQuery(GetClientGrowthRecommendationsPreviewDocument, { skip }),
  );
  return useMemo((): Result<GrowthRecommendation[], ApolloError> => {
    if (clientGrowthRecommendationsPreviewResult.state === ResultType.Value) {
      const { previewRecommendations } = clientGrowthRecommendationsPreviewResult.value.client;
      const growthRecommendations = extractGrowthRecommendationsPreview(previewRecommendations);
      const value = transformUserGrowthRecommendations(growthRecommendations);
      return { state: clientGrowthRecommendationsPreviewResult.state, value };
    }
    return clientGrowthRecommendationsPreviewResult;
  }, [clientGrowthRecommendationsPreviewResult]);
};

export const useMyRecommendations = ({
  options: { skip = false },
  ignoreRejected = true,
}: {
  options: Pick<QueryHookOptions, 'skip'>;
  ignoreRejected?: boolean;
}): Result<RiskRecommendation[], ApolloError> => {
  const myRecommendationsResult = useResultFromQuery(
    useQuery(GetMyWorkRecommendationsDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (myRecommendationsResult.state === ResultType.Value) {
      const recommendations = myRecommendationsResult.value.self.associated.recommendations.filter(
        (r) => !ignoreRejected || r.status !== ReefRecommendationStatus.Rejected,
      );
      const value = transformUserContractRecommendations(recommendations).sort((r1, r2) =>
        differenceInSeconds(r2.createdOn ?? 0, r1.createdOn ?? 0),
      );
      return { state: myRecommendationsResult.state, value };
    }
    return myRecommendationsResult;
  }, [myRecommendationsResult, ignoreRejected]);
};

export const useRecommendation = (id: UUID | null): Result<RiskRecommendation, ApolloError> => {
  // TODO: [sc-10706] support demo data/faker
  const recommendationResult = useResultFromQuery(
    useQuery(GetRecommendationDocument, {
      variables: id != null ? { id } : undefined,
      skip: id == null,
    }),
  );
  return useMemo((): Result<RiskRecommendation, ApolloError> => {
    if (recommendationResult.state === ResultType.Value) {
      if (recommendationResult.value.node.__typename === 'ReefContractRecommendation') {
        const recommendation = recommendationResult.value.node;
        const value = transformUserContractRecommendation(recommendation);
        return { state: recommendationResult.state, value };
      }
      return { state: ResultType.NoValue, value: undefined };
    }
    return recommendationResult;
  }, [recommendationResult]);
};
