import { Alert, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { filterCustomers$ } from '../../../selectors';

interface FilterAlertProps {
  message?: string;
  onDismiss(): void;
}
export const FilterAlert = ({ message, onDismiss }: FilterAlertProps) => {
  const [alertMessage, setAlertMessage] = useState<string>();
  useEffect(() => {
    if (message != null) {
      setAlertMessage(message);
    }
  }, [message]);
  return (
    <Collapse in={message != null} onExited={() => setAlertMessage(undefined)}>
      <Alert
        data-uid={filterCustomers$.editFilter.alert.component}
        severity="info"
        onClose={onDismiss}
        sx={{ borderRadius: 0 }}
      >
        {alertMessage}
      </Alert>
    </Collapse>
  );
};
