import {
  ExpandLess as ExpandLessIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { without } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { RecommendationExpiry, ReefRecommendationStatus } from '../../../../graphql/generated';
import type { RiskRecommendation } from '../../../../models/recommendation';
import { RISK_TYPE_LABELS, RiskRecommendationFields } from '../../../../models/recommendation';
import { userWork$ } from '../../../../selectors';
import { bottom } from '../../../../utils';
import { CreateSprintButton } from './CreateSprintButton';
import { RiskRecommendationField } from './RiskRecommendationField';

const ALL_RISK_REC_FIELDS: Array<keyof RiskRecommendationFields> = [
  'contractExpiringARR',
  'currentScore',
  'renewalOwnerEmail',
  'ownerEmail',
  'contractEndDate',
  'contractEndFiscalQuarter',
  'productNames',
  'link',
  'createdOn',
];
type RiskRecommendationFieldsProps = RiskRecommendation;
const RiskRecommendationFields = (rec: RiskRecommendationFieldsProps) => {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const recFields = useMemo((): (keyof RiskRecommendationFields)[] => {
    if (!showDemoData) {
      return without(ALL_RISK_REC_FIELDS, 'currentScore');
    }
    switch (rec.type) {
      case 'FULL_CHURN_CUSTOMER':
        return [
          'customerTotalARR',
          ...without(
            ALL_RISK_REC_FIELDS,
            'contractExpiringARR',
            'link',
            'contractEndDate',
            'contractEndFiscalQuarter',
            'productNames',
          ),
        ];
      case 'PARTIAL_CHURN_CONTRACT':
      case 'LOW_ENGAGEMENT':
      case 'PARTIAL_CHURN_PRODUCT':
      case 'PARTIAL_CHURN_PRODUCT_CONSUMPTION':
        return [...without(ALL_RISK_REC_FIELDS, 'productNames')];
      default:
        return ALL_RISK_REC_FIELDS;
    }
  }, [showDemoData, rec.type]);

  const fieldsData = useMemo<RiskRecommendationFields>(
    () => ({
      ...rec.account,
      ...rec.contract,
      createdOn: rec.createdOn,
      currentScore: rec.metrics?.currentScore ?? null,
      contractExpiringARR: rec.metrics?.relevantARR ?? rec.contract.expiringArr ?? null,
      customerTotalARR: rec.metrics?.relevantARR ?? null,
    }),
    [rec],
  );

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2.5}>
        {/* FIXME: use a grid layout? */}
        {recFields.slice(0, 5).map((rf) => (
          <Box key={`${rec.id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
            <RiskRecommendationField
              key={`${rf}-${rec.contract.id}`}
              recId={rec.id}
              field={rf}
              data={fieldsData}
            />
          </Box>
        ))}
      </Stack>
      <Stack direction="row" spacing={2.5}>
        {recFields.slice(5).map((rf) => (
          <Box key={`${rec.id}-${rf}-field-wrapper`} sx={{ width: '10rem' }}>
            <RiskRecommendationField
              key={`${rf}-${rec.contract.id}`}
              recId={rec.id}
              field={rf}
              data={fieldsData}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

interface CollapsibleRecommendationFieldsProps extends RiskRecommendation {
  sx?: SxProps;
}
const CollapsibleRecommendationFields = ({ sx, ...rec }: CollapsibleRecommendationFieldsProps) => {
  const [expanded, setExpanded] = useState(rec.expired === RecommendationExpiry.NotExpired);
  switch (rec.expired) {
    case RecommendationExpiry.NotExpired:
      return (
        <Box sx={sx}>
          <RiskRecommendationFields {...rec} />
        </Box>
      );
    case RecommendationExpiry.ContractInactive:
    case RecommendationExpiry.CustomerChurn: {
      // TODO: [sc-11609] we should join make this a generic component that growth + risk can use
      const churnedCopy =
        rec.expired === RecommendationExpiry.CustomerChurn
          ? 'Customer Churned'
          : 'Contract Inactive';
      const bgcolor =
        rec.expired === RecommendationExpiry.CustomerChurn ? '#0000001F' : '#ED6C021F';
      const color = rec.expired === RecommendationExpiry.CustomerChurn ? undefined : 'warning';
      return (
        <>
          <Box sx={{ ...sx, py: 2, bgcolor }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <WarningAmberIcon color={color} sx={{ my: 'auto' }} />
                <Typography color={color} sx={{ ml: 1, my: 'auto' }}>
                  {churnedCopy}
                </Typography>
              </Stack>
              <IconButton sx={{ justifySelf: 'end' }} onClick={() => setExpanded(!expanded)}>
                <ExpandLessIcon
                  sx={{
                    transform: expanded ? undefined : 'rotate(180deg)',
                    transition: (theme) => theme.transitions.create('transform'),
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
          <Collapse in={expanded}>
            <Box sx={sx}>
              <RiskRecommendationFields {...rec} />
            </Box>
          </Collapse>
        </>
      );
    }
    default:
      bottom(rec.expired);
  }
};

type SprintButtonProps = RiskRecommendation;

const SprintButton = ({ status, id, sprint, account, playbook, expired }: SprintButtonProps) => {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const viewSprintLink = useMemo(() => (sprint != null ? `/sprint/${sprint.id}` : null), [sprint]);
  switch (status) {
    case ReefRecommendationStatus.Accepted:
      return viewSprintLink != null ? (
        <Link component={RouterLink} to={viewSprintLink}>
          <Button
            variant="outlined"
            fullWidth
            data-uid={userWork$.recommendations.viewSprintBtn(id)}
          >
            View Sprint
          </Button>
        </Link>
      ) : (
        <Button
          variant="outlined"
          color="warning"
          fullWidth
          disabled
          data-uid={userWork$.recommendations.missingSprintBtn(id)}
        >
          Missing Sprint
        </Button>
      );
    case ReefRecommendationStatus.NoActionTaken:
      return (
        <CreateSprintButton
          accountId={account.id}
          accountName={account.name}
          recommendationId={id}
          playbookId={playbook.id}
          playbookTitle={playbook.title}
          showDemoData={showDemoData}
          createDisabled={expired !== RecommendationExpiry.NotExpired}
        />
      );
    case ReefRecommendationStatus.Rejected:
      return (
        <Button
          variant="outlined"
          fullWidth
          color="info"
          data-uid={userWork$.recommendations.rejectedSprintBtn(id)}
        >
          No Sprint
        </Button>
      );
    default:
      bottom(status);
  }
};

type RiskRecommendationItemProps = RiskRecommendation;
export const RiskRecommendationItem = (rec: RiskRecommendationItemProps) => {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const chipInfo = useMemo(() => {
    if (!showDemoData) {
      return {
        label: 'Risk',
        backgroundColor: undefined,
      };
    }
    return {
      label: RISK_TYPE_LABELS[rec.type] ?? 'Risk',
      backgroundColor: 'rgba(255, 99, 49, 0.30)',
    };
  }, [showDemoData, rec.type]);
  return (
    <Card
      sx={{
        maxWidth: '60rem',
        '& p': {
          fontFamily: 'Inter',
        },
        overflow: 'visible',
        borderColor: chipInfo.backgroundColor,
      }}
      variant="outlined"
      data-uid={userWork$.recommendations.item(rec.id)}
      data-rec-id={rec.id}
      data-rec-status={rec.status}
      data-rec-sprint-id={rec.sprint?.id}
      data-rec-created-on={rec.createdOn}
      data-rec-contract-renewal-score={rec.contract.usageRating}
    >
      <Stack direction="column" spacing={1} sx={{ p: 4 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="column" spacing={0.25} flexGrow={1} sx={{ mt: -1 }}>
            <Box>
              <Chip label={chipInfo.label} sx={{ backgroundColor: chipInfo.backgroundColor }} />
            </Box>
            <Typography
              sx={{
                fontSize: '1.375rem',
                maxWidth: '45rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              data-uid={userWork$.recommendations.name}
              title={rec.title}
            >
              {rec.title}
            </Typography>
            <Link
              component={RouterLink}
              to={`/customer-details/${rec.account.id}`}
              data-uid={userWork$.recommendations.accountLink(rec.id)}
              sx={{
                maxWidth: '45rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: '1.125rem',
                  fontFamily: 'Inter',
                }}
                title={rec.account.name}
              >
                {rec.account.name}
              </Typography>
            </Link>
          </Stack>
          <SprintButton {...rec} />
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: chipInfo.backgroundColor }} />
      <CollapsibleRecommendationFields sx={{ p: 4 }} {...rec} />
    </Card>
  );
};
