// Required CSS import, unless you're overriding the styling
import '@knocklabs/react/dist/index.css';

import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedContainer,
  NotificationFeedPopover,
  NotificationIconButton,
} from '@knocklabs/react';
import { NotificationsOffOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

import { AuthStatus, useReefAuthContext } from '../../contexts/reefAuthContext';
import { useKnock } from '../../hooks/knock';
import { MISSING_USER, useCurrentUserDetails } from '../../hooks/user';

export const NotificationsFeed = () => {
  const [authCtx] = useReefAuthContext();
  const { user } = useCurrentUserDetails();
  const [isVisible, setIsVisible] = useState(false);
  const notificationsButtonRef = useRef(null);

  const { knockKeys } = useKnock();
  const { isImpersonating, userToken } = useMemo(
    () =>
      authCtx.status === AuthStatus.SignedIn
        ? {
            isImpersonating: authCtx.session.isImpersonating,
            userToken: authCtx.session.knockToken,
          }
        : {},
    [authCtx],
  );

  if (user === MISSING_USER) {
    return <CircularProgress />;
  }
  if (isImpersonating || userToken == null) {
    return (
      <IconButton disabled>
        <NotificationsOffOutlined />
      </IconButton>
    );
  }

  return (
    <KnockProvider apiKey={knockKeys.apiKey} userId={user.id} userToken={userToken}>
      <KnockFeedProvider feedId={knockKeys.feedId}>
        <NotificationFeedContainer>
          <NotificationIconButton
            ref={notificationsButtonRef}
            onClick={() => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            buttonRef={notificationsButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </NotificationFeedContainer>
      </KnockFeedProvider>
    </KnockProvider>
  );
};
