import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Typography } from '@mui/material';
import React, { useState } from 'react';

import { filterCustomers$ } from '../../../selectors';

interface FilterAccordionProps {
  /**
   * Name of the filter segment.
   */
  name: string;
  /**
   * The number of filters in this segment.
   */
  numFilters: number;
  disabled?: boolean;
  /**
   * If `true`, this accordion _starts_ open.
   */
  initiallyOpen: boolean;
  children: React.ReactNode;
}
export const FilterAccordion = ({
  name,
  numFilters,
  disabled,
  initiallyOpen,
  children,
}: FilterAccordionProps) => {
  const [open, setOpen] = useState(initiallyOpen);
  return (
    <Accordion
      data-uid={filterCustomers$.editFilter.accordion.component(name)}
      disabled={disabled}
      expanded={open}
      onChange={() => setOpen(!open)}
      disableGutters
      square
      elevation={0}
      sx={{ backgroundColor: (theme) => theme.palette.magic.drawerBackground.main }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              right: -20,
              top: 13,
              bgcolor: (theme) => theme.palette.action.selected,
            },
          }}
          invisible={numFilters === 0}
          badgeContent={numFilters}
        >
          <Typography>{name}</Typography>
        </Badge>
      </AccordionSummary>
      <AccordionDetails sx={{ bgcolor: (theme) => theme.palette.background.paper, py: 3, px: 2 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
