import { replayIntegration } from '@sentry/browser';
import {
  captureConsoleIntegration,
  init as sentryInit,
  reactRouterV6BrowserTracingIntegration,
  setTag,
  setUser,
} from '@sentry/react';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import type { AnalyticsContext } from './reefAnalyticsContext';
import { ReefAnalyticsContext } from './reefAnalyticsContext';

const ANONYMOUS_ID = 'anonymous';

const BASE_SENTRY_CONFIG = {
  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/captureconsole/
    captureConsoleIntegration({
      levels: ['error'],
    }),
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#set-up
    replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      // See below for all available options
    }),
  ],
  sampleRate: 1.0, // send all errors
  maxBreadcrumbs: 50, // halve crumbs
  tracesSampleRate: 0.2, // only analyze ~20% of network traffic
  replaysSessionSampleRate: 0.1, // only sample 10% in prod
  replaysOnErrorSampleRate: 1.0, // bump to 100% when error occurs
};

interface ReefAnalyticsProviderProps extends PropsWithChildren {
  sentryDSN?: string;
  sentryEnv?: string;
}
export const ReefAnalyticsProvider = ({
  children,
  sentryDSN,
  sentryEnv,
}: ReefAnalyticsProviderProps) => {
  const [ctx, setCtx] = useState<AnalyticsContext>({ identify: () => null, reset: () => null });

  const ldClient = useLDClient();

  const init = useCallback(() => {
    if (pendo) {
      // all users start as anonymous
      pendo.initialize({
        visitor: {
          id: ANONYMOUS_ID,
        },
        account: {
          id: ANONYMOUS_ID,
        },
      });
    }

    // Heap initialized via header script
    if (heap) {
      heap.identify(ANONYMOUS_ID);
    }

    if (sentryDSN) {
      const environment = sentryEnv ?? 'local';
      sentryInit({
        ...BASE_SENTRY_CONFIG,
        dsn: sentryDSN,
        environment,
        enabled: environment !== 'local', // only send local errors intentionally
      });
    }
    setUser(null);
  }, [sentryDSN, sentryEnv]);

  const identify: AnalyticsContext['identify'] = useCallback(
    (session: CognitoUserSession) => {
      const payload = session.getIdToken().decodePayload();

      const isImpersonating = payload['impersonation_client_id'] != null;
      const trueUserId = (isImpersonating ? payload['sub'] : payload['user_id']) ?? ANONYMOUS_ID;
      const effectiveUserId =
        (isImpersonating ? payload['impersonation_user_id'] : trueUserId) ?? ANONYMOUS_ID;
      const trueClientId = payload['client_id'];
      const effectiveClientId = isImpersonating ? payload['impersonation_client_id'] : trueClientId;
      const email = payload['email'] || payload['cognito:username'];
      const name = payload['name'];

      // identify the user for launch darkly
      ldClient?.identify({
        kind: 'user',
        key: effectiveUserId,
        email,
        name,
        clientId: effectiveClientId,
      });

      // NOTE: identify the user for Knock if we drop inline identify in API

      // identify the user for Pendo
      if (pendo) {
        pendo.identify({
          visitor: {
            id: trueUserId,
            email,
            full_name: name,
            is_impersonating: isImpersonating,
          },
          account: {
            // TODO: do we need to update this for skeleton users or leave it as anonymous?
            id: trueClientId ?? ANONYMOUS_ID,
          },
        });
      }
      if (heap) {
        heap.identify(trueUserId);
        heap.addUserProperties({
          email,
          full_name: name,
          is_impersonating: isImpersonating,
          client_id: trueClientId ?? ANONYMOUS_ID,
          ...(isImpersonating
            ? {
                impersonation_user_id: effectiveUserId,
                impersonation_client_id: effectiveClientId,
              }
            : {}),
        });
      }
      // identify the user for Sentry
      setUser({
        id: trueUserId,
        username: email,
        email,
        client: trueClientId ?? ANONYMOUS_ID,
        is_impersonating: isImpersonating,
      });
      setTag('user_email', email);
      setTag('client_id', effectiveClientId);
    },
    [ldClient],
  );

  const reset: AnalyticsContext['reset'] = useCallback(() => {
    if (pendo) {
      // this is typed as a non-optional property of pendo, but it won't exist in
      // our e2e context
      pendo.clearSession?.();
    }
    if (heap) {
      heap.resetIdentity();
    }
    ldClient?.identify({ kind: 'user', anonymous: true });
    setUser(null);
  }, [ldClient]);

  useEffect(() => init(), [init]);
  useEffect(() => setCtx({ identify, reset }), [identify, reset]);

  return <ReefAnalyticsContext.Provider value={ctx}>{children}</ReefAnalyticsContext.Provider>;
};
