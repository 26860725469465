import type React from 'react';
import { createContext, useContext } from 'react';

import type { AccountXAxisMetric, AccountYAxisMetric } from '../hooks/useAccountChartConfig';

type HeatmapContextType = {
  xAxisMetric: AccountXAxisMetric;
  setXAxisMetric: React.Dispatch<React.SetStateAction<AccountXAxisMetric>>;
  yAxisMetric: AccountYAxisMetric;
  setYAxisMetric: React.Dispatch<React.SetStateAction<AccountYAxisMetric>>;
};
export const HeatmapContext = createContext<HeatmapContextType>({
  xAxisMetric: 'engagement',
  setXAxisMetric: () => null,
  yAxisMetric: 'potential',
  setYAxisMetric: () => null,
});

export const useHeatmapContext = () => useContext(HeatmapContext);
