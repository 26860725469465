/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

const OAUTH_STATE = 'oauth_state';
const OAUTH_PKCE_KEY = 'oauth_pkce_key';

export const setState = (state: string) => {
  window.sessionStorage.setItem(OAUTH_STATE, state);
};

export const getState = () => {
  const oauth_state = window.sessionStorage.getItem(OAUTH_STATE);
  window.sessionStorage.removeItem(OAUTH_STATE);
  return oauth_state;
};

export const setPKCE = (private_key: string) => {
  window.sessionStorage.setItem(OAUTH_PKCE_KEY, private_key);
};

export const getPKCE = () => {
  const oauth_pkce_key = window.sessionStorage.getItem(OAUTH_PKCE_KEY);
  window.sessionStorage.removeItem(OAUTH_PKCE_KEY);
  return oauth_pkce_key;
};

export const clearAll = () => {
  window.sessionStorage.removeItem(OAUTH_PKCE_KEY);
  window.sessionStorage.removeItem(OAUTH_STATE);
};
