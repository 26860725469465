import type { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

/**
 * A collection of flags used to configure the Reef.ai app.
 *
 * The values of these flag names are just camel-case versions of the flags in launch darkly
 * because launch darkly is nice enough to do that for us.
 */
export enum ReefFlag {
  /**
   * Launch darkly [`heatmap-default-metrics`](https://app.launchdarkly.com/default/production/features/heatmap-default-metrics/targeting)
   */
  HeatmapDefaultMetrics = 'heatmapDefaultMetrics',

  /**
   * Launch darkly [`username-password-login-enabled`](https://app.launchdarkly.com/default/production/features/username-password-login-enabled/targeting)
   */
  UsernamePasswordLoginEnabled = 'usernamePasswordLoginEnabled',

  /**
   * Launch darkly [`hide-unique-users-drilldown-field`](https://app.launchdarkly.com/default/production/features/hide-unique-users-drilldown-field/targeting)
   */
  HideUniqueUsersDrilldownField = 'hideUniqueUsersDrilldownField',

  /**
   * Launch darkly [`hide-advanced-users-drilldown-field`](https://app.launchdarkly.com/default/production/features/hide-advanced-users-drilldown-field/targeting)
   */
  HideAdvancedUsersDrilldownField = 'hideAdvancedUsersDrilldownField',

  /**
   * Launch darkly [`hide-overage-dollars-drilldown-field`](https://app.launchdarkly.com/default/production/features/hide-overage-dollars-drilldown-field/targeting)
   */
  HideOverageDollarsDrilldownField = 'hideOverageDollarsDrilldownField',

  /**
   * Launch darkly [`hide-overage-products-drilldown-field`](https://app.launchdarkly.com/default/production/features/hide-overage-products-drilldown-field/targeting)
   */
  HideOverageProductsDrilldownField = 'hideOverageProductsDrilldownField',

  /**
   * Launch darkly [`apollo-devtools-enabled`](https://app.launchdarkly.com/default/production/features/apollo-devtools-enabled/targeting)
   */
  ApolloDevtoolsEnabled = 'apolloDevtoolsEnabled',

  /**
   * Launch darkly [`customer-360-pdf-enabled`](https://app.launchdarkly.com/default/production/features/customer-360-pdf-enabled/targeting)
   */
  Customer360PDFEnabled = 'customer360PdfEnabled',

  /**
   * Launch darkly [`renewal-analytics-enabled`](https://app.launchdarkly.com/default/production/features/renewal-analytics-enabled/targeting)
   */
  RenewalAnalyticsEnabled = 'renewalAnalyticsEnabled',

  /**
   * Launch darkly [`show-legacy-products`](https://app.launchdarkly.com/default/production/features/show-legacy-products/targeting)
   */
  ShowLegacyProducts = 'showLegacyProducts',

  /**
   * Launch darkly [`hide-product-filters`](https://app.launchdarkly.com/default/production/features/hide-product-filters/targeting)
   */
  HideProductFilters = 'hideProductFilters',
}

/**
 * Type to protect ourselves. Each flag should have a default and this type
 * will cause a compile failure on the `defaultFlags` constant.
 */
type ReefLDFlagSet = LDFlagSet & { [K in ReefFlag]: unknown };

/**
 * Types we expect to get back from launch darkly config.
 */
interface ReefFlags extends ReefLDFlagSet {
  [ReefFlag.HeatmapDefaultMetrics]: { x: string; y: string };
  [ReefFlag.UsernamePasswordLoginEnabled]: boolean;
  [ReefFlag.HideAdvancedUsersDrilldownField]: boolean;
  [ReefFlag.HideUniqueUsersDrilldownField]: boolean;
  [ReefFlag.HideOverageDollarsDrilldownField]: boolean;
  [ReefFlag.HideOverageProductsDrilldownField]: boolean;
  [ReefFlag.ApolloDevtoolsEnabled]: boolean;
  [ReefFlag.Customer360PDFEnabled]: boolean;
  [ReefFlag.RenewalAnalyticsEnabled]: boolean;
  [ReefFlag.ShowLegacyProducts]: boolean;
  [ReefFlag.HideProductFilters]: boolean;
}

/**
 * Default flags that we can use while launch darkly is loading or if we get no value back.
 */
const defaultFlags: ReefFlags = {
  [ReefFlag.HeatmapDefaultMetrics]: { x: 'engagement', y: 'potential' },
  [ReefFlag.UsernamePasswordLoginEnabled]: false,
  [ReefFlag.HideAdvancedUsersDrilldownField]: false,
  [ReefFlag.HideUniqueUsersDrilldownField]: false,
  [ReefFlag.HideOverageDollarsDrilldownField]: false,
  [ReefFlag.HideOverageProductsDrilldownField]: false,
  [ReefFlag.ApolloDevtoolsEnabled]: false,
  [ReefFlag.Customer360PDFEnabled]: false,
  [ReefFlag.RenewalAnalyticsEnabled]: false,
  [ReefFlag.ShowLegacyProducts]: false,
  [ReefFlag.HideProductFilters]: false,
};

/**
 *
 * @returns a collection of launch darkly flags
 */
export const useReefFlags = () => {
  const flags = useFlags();

  return useMemo(() => ({ ...defaultFlags, ...flags }), [flags]);
};
