import { format, type Locale as DateFnsLocale, setDefaultOptions } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CURRENCY_SYMBOLS } from '../constants';
import { useClientConfig } from '../hooks/client';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useCurrentUserDetails } from '../hooks/user';
import type { ArrayElement } from '../types/util';
import { AuthStatus, useReefAuthContext } from './reefAuthContext';
import type { UserSettingsContextType } from './userSettingsContext';
import {
  DEFAULT_CURRENCY_NUMBER_FORMAT_OPTIONS,
  DEFAULT_SHORT_CURRENCY_NUMBER_FORMAT_OPTIONS,
  UserSettingsContext,
} from './userSettingsContext';

const SUPPORTED_LANGUAGES = ['en-US', 'en-AU'] as const;
type ReefLanguage = ArrayElement<typeof SUPPORTED_LANGUAGES>;

const isReefLanguage = (value: string): value is ReefLanguage =>
  SUPPORTED_LANGUAGES.find((lang) => lang === value) != null;

/**
 * Hook to get the user's language.
 * @returns locale
 */
function useLanguage(): ReefLanguage {
  const getLang = (prev: ReefLanguage = 'en-US'): ReefLanguage => {
    const { language, languages } = navigator;
    const lang = language || (languages.length && languages[0]) || 'en';
    if (prev !== lang && isReefLanguage(lang)) {
      return lang;
    }
    return prev;
  };
  const [lang] = useState(getLang);
  return lang;
}

/**
 * Hook to get the date-fns locale object.
 * @param lang language to get
 * @returns date-fns locale object
 */
function useDateFnsLocale(lang: ReefLanguage): DateFnsLocale | undefined {
  const [locale, setLocale] = useState<DateFnsLocale>();
  useEffect(() => {
    const loadDateFnsLocale = async () => {
      switch (lang) {
        // add support for localizing dates here
        case 'en-AU': {
          const { enAU } = await import('date-fns/locale/en-AU');
          setLocale(enAU);
          break;
        }
        case 'en-US':
        default: {
          const { enUS } = await import('date-fns/locale/en-US');
          setLocale(enUS);
          break;
        }
      }
    };
    loadDateFnsLocale().catch((err) => console.error(err));
  }, [lang]);
  return locale;
}

export const UserSettingsProvider = ({ children }: React.PropsWithChildren) => {
  const { user, clientId } = useCurrentUserDetails();
  const [config] = useClientConfig();
  const [authCtx] = useReefAuthContext();
  const [persistedShowDemoData, setPersistedShowDemoData] = useLocalStorage(
    `reefai_user_settings--${clientId}--${user.id}--show_demo_data`,
    false,
  );
  const [persistedShowUserSwitcher, setPersistedShowUserSwitcher] = useLocalStorage(
    `reefai_user_settings--show_user_switcher`,
    false,
  );

  const dateLang = useLanguage();
  const dateLocale = useDateFnsLocale(dateLang);
  useEffect(() => setDefaultOptions({ locale: dateLocale }), [dateLocale]);

  const defaultCurrencyCode = useMemo(
    () => user.defaultCurrencyCode ?? config.defaultBusinessCurrencyCode ?? 'USD',
    [config.defaultBusinessCurrencyCode, user.defaultCurrencyCode],
  );
  const [currencyCode, setCurrencyCode] = useState<string>(defaultCurrencyCode);
  const currencyLang = useMemo(
    () => (navigator.languages ? navigator.languages[0] : (navigator.language ?? 'en-US')),
    [],
  );
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(currencyLang, {
        ...DEFAULT_CURRENCY_NUMBER_FORMAT_OPTIONS,
        currency: currencyCode,
      }),
    [currencyCode, currencyLang],
  );
  const isUsingDefaultBusinessCurrencyCode = useMemo(
    () => currencyCode === config.defaultBusinessCurrencyCode,
    [config.defaultBusinessCurrencyCode, currencyCode],
  );
  const currencySymbol = useMemo(() => CURRENCY_SYMBOLS[currencyCode], [currencyCode]);
  const formatCurrencyShort = useCallback<UserSettingsContextType['formatCurrencyShort']>(
    (amount, significantDigits = 3) =>
      amount != null
        ? new Intl.NumberFormat(currencyLang, {
            ...DEFAULT_SHORT_CURRENCY_NUMBER_FORMAT_OPTIONS,
            currency: currencyCode,
            maximumSignificantDigits: significantDigits,
          }).format(amount)
        : '–',
    [currencyCode, currencyLang],
  );
  const isImpersonating = useMemo(
    () => authCtx.status === AuthStatus.SignedIn && authCtx.session.isImpersonating,
    [authCtx],
  );
  const showDemoData = useMemo(
    () => (isImpersonating ? (persistedShowDemoData ?? false) : false),
    [isImpersonating, persistedShowDemoData],
  );

  const showUserSwitcher = useMemo(
    // TODO: persisting/showing the user switcher can't really be based on the impersonation state
    // because the act of using the impersonation context implies you are using the switcher
    // we should come up with some better state management for users that can change impersonation
    // but this likely requires some auth token changes
    () => (isImpersonating ? (persistedShowUserSwitcher ?? false) : false),
    [isImpersonating, persistedShowUserSwitcher],
  );

  useEffect(() => setCurrencyCode(defaultCurrencyCode), [defaultCurrencyCode]);

  return (
    <UserSettingsContext.Provider
      value={{
        dates: { locale: dateLocale, format },
        currencyCode,
        currencyFormatter,
        currencySymbol,
        isUsingDefaultBusinessCurrencyCode,
        formatCurrencyShort,
        admin: {
          isImpersonating,
          showDemoData,
          setShowDemoData: setPersistedShowDemoData,
          showUserSwitcher,
          setShowUserSwitcher: setPersistedShowUserSwitcher,
        },
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};
