interface HeatmapMetricBucketConfig<T extends string | number | symbol> {
  labels: T[];
  total: number;
  metricLabel: string;
}
export interface HeatmapClientConfig {
  buckets: {
    customHealthscore: HeatmapMetricBucketConfig<string>;
    // TODO: refactor to `customerRevenue` field/key
    customerRevenueBand: HeatmapMetricBucketConfig<string>;
    risk: HeatmapMetricBucketConfig<string>;
    growth: HeatmapMetricBucketConfig<string>;
  };
}
export interface ClientConfig extends HeatmapClientConfig {
  currencyCodes: string[];
  defaultBusinessCurrencyCode: string;
  customerRegions: string[] | null;
}

export const EMPTY_CLIENT_CONFIG: ClientConfig = {
  buckets: {
    customerRevenueBand: {
      labels: [],
      total: 0,
      metricLabel: 'Revenue Band',
    },
    customHealthscore: {
      labels: [],
      total: 0,
      metricLabel: 'Healthscore',
    },
    risk: {
      labels: [],
      total: 0,
      metricLabel: 'Risk Score',
    },
    growth: {
      labels: [],
      total: 0,
      metricLabel: 'Growth Score',
    },
  },
  currencyCodes: ['USD'],
  defaultBusinessCurrencyCode: 'USD',
  customerRegions: [],
};
